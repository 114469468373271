import { useTheme } from "@emotion/react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import HeaderFrameLayout from "../../../../globalComponents/Headers/HeaderFrames/FrameLayout/FrameLayout";








export default function ClinicianFrame({ 
    billableEvent, 
    encounter, 
    pendingQA,
    
}){

    const theme = useTheme()
    let {encounterType} = useParams()


    //fields that differ between Pathology and InfecDis
    const dateSubmitted = encounterType.includes('Pathology') ? encounter.date_submitted : encounter.submitted_at;
    const duration = encounterType.includes('Pathology') ? encounter?.time_spent_reviewing_entered : encounter?.general?.time_spent_reviewing_entered_in_minutes
    const treatingProvider = encounterType.includes('Pathology') ? encounter?.treating_provider : encounter?.general?.treating_provider
    const billingPts = pendingQA ? 'QA pending' :  billableEvent.comp_value

    const firstRow = [ 
        { label:'Submitted', value: dayjs(dateSubmitted).format('LL') || '-' }, 
        { label:'Duration', value: duration? duration + ' min' : '-'}, 
        { label:'Billing pts.', value: billingPts, 
            valueSx: pendingQA? {color:theme.palette.warning.main} : null }, 
        { label:'Site', value: encounter.patient.site.site_name }, 
    ]

    const secondRow = [ 
        { label:'Treating Provider', value: treatingProvider}, 
        { label:'CPT Codes', value: billableEvent.cpt_codes.map(event => event.cpt_code.code).join(', ') }, 
    ]

    return(
        <HeaderFrameLayout
            avatarColor={theme.avatar.primary}
            name={encounter?.clinician_name}
            firstRow={firstRow}
            secondRow={secondRow}/>
    )
}