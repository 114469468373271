import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ActionButton } from '../../../../../../globalComponents/Buttons/ActionButton/ActionButton';
import AddIcon from '@mui/icons-material/Add';
import { Chip } from '@mui/material';











export function AllowedSites({allowedSites=[]}){



    return(
      <TableContainer sx={(theme) => ({...theme.standardBox, height:340, flex:1, 'th':{background: theme.palette.background.paper},  minWidth:'fit-content'})}>
        <Table size='small' stickyHeader >
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Sites</TableCell>
              <TableCell colSpan={1} align='right'>
                  <ActionButton 
                      startIcon={<AddIcon />} 
                      sx={{'span': {mr:0.5}}} >
                      Add site
                  </ActionButton>            
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allowedSites?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell >
                  {row.site_name}
                </TableCell>
                <TableCell>
                  {/* <Chip label={row.user.employment_type} variant='outlined' color='primary' size='small'/> */}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )


}