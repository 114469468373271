import { Box } from "@mui/material";
import StatCard from "../../../../../globalComponents/Cards/StatCard/StatCard";
import { useDispatch, useSelector } from "react-redux";
import { selectRosterMgmtStatSpotlight, setRosterMgmtStat } from "../../../../../admin/adminSlice";
import { useGetCasePerformanceStatsQuery} from "../../../../rosterAdminAPISlice";
import AsgmtStatChart from "../AsgmtStatChart/AsgmtStatChart";
import StatSpotlightCharts from "../StatSpotlightChart/StatSpotlightCharts";






export default function OverviewCaseStats({dates}){

    const dispatch = useDispatch()
    const stat = useSelector(selectRosterMgmtStatSpotlight)
    
    const { data, isLoading, isFetching } = useGetCasePerformanceStatsQuery({dates})
    const { requested, requested_change, assigned, assigned_change, completed, completed_change, patients, patients_change, day_period } = data || {}


    const selected = (label) => stat === label
    const setStat = (val) => (e) => dispatch(setRosterMgmtStat(val))


    return(
        <>
            <Box className='flex-row' gap={1} flexWrap={'wrap'}>
                <StatCard 
                    label='Patients'
                    stat={patients}
                    percentDiff={patients_change}
                    selected={selected('Patients')}
                    setStat={setStat('Patients')}
                    dayPeriod={day_period}
                    dates={dates}
                    loading={isLoading || isFetching}/>
                <StatCard 
                    label='Requested'
                    stat={requested}
                    percentDiff={requested_change}
                    selected={selected('Requested')}
                    setStat={setStat('Requested')}
                    dayPeriod={day_period}
                    dates={dates}
                    loading={isLoading || isFetching}/>
                <StatCard 
                    label='Assigned'
                    stat={assigned}
                    percentDiff={assigned_change}
                    selected={selected('Assigned')}
                    setStat={setStat('Assigned')}
                    endComponent={<AsgmtStatChart  dates={dates}/>}
                    dayPeriod={day_period}
                    dates={dates}
                    loading={isLoading || isFetching}
                    />
                <StatCard 
                    label='Completed'
                    stat={completed}
                    percentDiff={completed_change}
                    selected={selected('Completed')}
                    setStat={setStat('Completed')}
                    dayPeriod={day_period}
                    dates={dates}
                    loading={isLoading || isFetching}/>
            </Box>
            <StatSpotlightCharts stat={stat} dates={dates}/>
        </>
    )
}