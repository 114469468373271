import { Box } from "@mui/material";
import { Info } from "./Info/Info";
import { AllowedSites } from "./AllowedSites/AllowedSites";










export default function ClinicianInfoAndSettings({clinicianData}){
    const {clinician_info, weekly_schedule, allowed_sites} = clinicianData || {}


    return(
        <Box className='flex-row' gap={2} flexWrap='wrap'>
            <Info info={clinician_info} schedule={weekly_schedule}/>
            <AllowedSites allowedSites={allowed_sites}/>
        </Box>
    )


}