import {useDropzone} from 'react-dropzone'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Box, Button, Typography } from '@mui/material';
import { FileUploadIcon } from '../../../assets/icons/MaterialDesignIcons';

const getAcceptedFileTypes = (types) => {
    const MIMETypes ={
        pdf : {'application/pdf': ['.pdf'] },
        csv : { 'text/csv': ['.csv'] }
    }

    let fileTypes = {}

    types?.map((type) => {
        if (MIMETypes?.[type]) fileTypes = {...fileTypes, ...MIMETypes[type]}
    })

    return fileTypes

}


export default function HaloDropZone({
    fileTypes=['pdf'], // specify what file types the fil input should take, default is pdf files only...
                        //... can take multiple e.g. ['pdf', 'csv']
    dropZoneSx={}
}){


    const { getRootProps, getInputProps } = useDropzone({
        accept: getAcceptedFileTypes(fileTypes), 
        maxFiles: 1
    });

    return(
        <div {...getRootProps()} >
            <input  {...getInputProps({name: 'documentation', type:'file'})}/>
            <Box className='flex-col-center' 
                sx={({palette}) => ({
                    border: `1px dashed ${palette.primary.main}`,
                    p: '24px 16px',
                    ...dropZoneSx
                })}>
                    <FileUploadIcon color='primary'/>
                    <Typography variant='subtitle1'>
                        <Button variant="text" color="primary" sx={{p: 0, textDecoration:'underline'}}>
                            Click to upload 
                        </Button> or drag and drop
                </Typography>
            </Box>
        </div>
    )

}