import {  useParams } from "react-router-dom"
import { useGetEncounterDetialsQuery } from "../rosterAPISlice"
import { Box, Typography} from "@mui/material"
import PathologyDetailsContainer from "../../pathology/components/PathologyReview/PathologyDetailsContainer"
import InfectiousDiseaseDetailsContainer from "../../infectiousDisease/components/infectiousDiseaseReview/InfectiousDiseaseDetailsContainer"
import MainQAFeedbackContainer from "../../qualityAssurance/components/QAFeedback/MainQAFeedbackContainer"
import { useCallback, useEffect, useState } from "react"
import FaxModal from "../components/FaxModal/FaxModal"
import CaseDetailsNav from "../components/CaseNavHeaders/CaseDetailsNav"
import { useDispatch } from "react-redux"
import { setStaticMeds } from "../../pathology/pathologySlice"

//This is what looked at to view past encounter
//Includes encounters pre-RM (i.e. encounters with no CaseObject)

export default function CaseDetails(){

    let {encounterType, encounterId} = useParams()
    const dispatch = useDispatch()
    const {data, isLoading, isFetching} = useGetEncounterDetialsQuery({encounterType, encounterId})
    const {encounter, billable_event, is_same_day_editable, case_info, qa_obj } = data || {}

    
    const [open, setOpen] = useState(false)
    const openFaxModal = useCallback(() =>{ setOpen(true) }, []) 
    const isInfecDisCase = encounterType.includes('Infectious Disease');

    useEffect(() => {

        dispatch(setStaticMeds(encounter?.patient?.medications))

    }, [data])

   
    return(
        <>
            <CaseDetailsNav
                encounter={encounter}
                encounterId={encounterId}
                isInfecDisCase={isInfecDisCase}
                billableEvent={billable_event}
                qaObj={qa_obj}
                openFaxModal={openFaxModal}
                isLoading={isFetching || isLoading}
            />
                
            <Box className='flex-col' p={3} gap={3} >
                    <Typography variant="h6">
                        {isInfecDisCase? 'Infectious disease case' : 'Pathology case'}
                    </Typography>
                {
                    qa_obj?.review_state_string === 'approved' &&
                    <MainQAFeedbackContainer qaObj={qa_obj}/>
                }
                {
                    isInfecDisCase?

                    <InfectiousDiseaseDetailsContainer 
                        isLoading={isLoading || isFetching}
                        encounter={encounter} 
                        allowEdits={is_same_day_editable}
                        caseInfo={case_info}
                        />
                    :
                    <PathologyDetailsContainer 
                        isLoading={isLoading || isFetching}
                        encounter={encounter} 
                        allowEdits={is_same_day_editable}
                        caseInfo={case_info}
                        qaObj={qa_obj?.review_state_string === 'approved'? qa_obj : null}/>
                }
            </Box>
            <FaxModal patientId={encounter?.patient?.id} open={open} setOpen={setOpen}/>
        </>
    )


}
