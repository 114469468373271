import TimelineItem  from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useTheme } from '@emotion/react';
import { Box,  Typography } from '@mui/material';
import dayjs from 'dayjs';
import { getEntryIcon } from '../PatientSummary/PatientHistory/HistoryEntry/EntryIcon/getEntryIcon';


export default function IPCEntry({entry}){

    const theme = useTheme()


    return(
        <TimelineItem>
            <TimelineSeparator>
                    {getEntryIcon('IPC', theme)}
                <TimelineConnector/>
            </TimelineSeparator>
            <TimelineContent className='flex-col' >
                <Box className='flex-row'>
                    <Typography sx={{...theme.typography.body2}}>
                        IPC Consult
                    </Typography>
                </Box>
                <Box className='flex-col'>
                    <Typography variant='caption' sx={{ color:theme.palette.text.disabled}}>
                        Date submitted: {dayjs(entry.created_at).format("MMM DD YYYY")}
                    </Typography>
                    <Typography variant='caption' sx={{ color: theme.palette.text.disabled}}>
                        Details: {entry.details}
                    </Typography>
                    <Typography variant='caption' sx={{ color: theme.palette.text.disabled}}>
                        Comm: {entry.communication_method}
                    </Typography>
                </Box>
               
            </TimelineContent>
        </TimelineItem>
    )
}