import { useTheme } from "@emotion/react";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { stringToTitleCase } from "../../../../../utils/stringUtils/titleCase";
import FrameRow from "./FrameRow";







export default function HeaderFrameLayout({
    avatarColor,
    name,
    icon,
    iconLinkPath,
    iconButtonProps,
    firstRow = [],  //rows take format of [ label:'', value:'' ]
    secondRow = [], //rows take format of [ label:'', value:'' ]

}) {


    const theme = useTheme()
    const navigate = useNavigate()

    const iconLink = () => navigate(iconLinkPath)

    return (
        <Box className='flex-col' gap={1} flexGrow={1}>
            <Box className='flex-row-between-center'>
                <Box className='flex-row-center' gap={2}>
                    <Avatar sx={{ bgcolor: avatarColor }}>
                        {name?.slice(0, 1)?.toUpperCase() || ''}
                    </Avatar>
                    <Typography sx={{ ...theme.typography.h5, fontWeight: 500 }} >
                        {stringToTitleCase(name)}
                    </Typography>
                </Box>
                {
                    icon ?
                        <IconButton
                            onClick={iconLink}
                            {...iconButtonProps}
                            sx={{
                                height: 28,
                                width: 28,
                                borderRadius: 12,
                            }}>
                            {icon}
                        </IconButton>
                        : null
                }
            </Box>
            <Box className='flex-col' gap={2}>
                <FrameRow items={firstRow} />
                {secondRow.length != 0 ? <FrameRow items={secondRow} /> : null}
            </Box>
        </Box>
    )
}
