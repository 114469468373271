import DashboardStats from "./SPSections/DasboardStats";
import DashboardPreferences from "./SPSections/DashboardPreferences";
import DashboardPointsGoal from "./SPSections/DashboardPointsGoal";
import { useState } from "react";
import CaseSchedulingModal from "../../../rosterManagement/components/SchedulingModal/CaseSchedulingModal";
import { useDispatch } from "react-redux";
import { apiSlice } from "../../../../app/api/apiSlice";
import dayjs from "dayjs";
import { usePrefetchEndpoint } from "../../../../utils/apiUtils/usePrefetchEndpoint";





export default function StatsAndPreferences({ currentWeekCaseLoad, stats, billingPoints, pointsGoalObj, weekRangeStr}){
    

    const [open, setOpen] = useState(false)
    const prefetch = usePrefetchEndpoint() 
    // const dispatch = useDispatch()
    const openModal = () => setOpen(true)
    const closeModal = () => setOpen(false)

    const prefetchSchedules = () => {
        prefetch('getMonthSchedules', dayjs().format('YYYY-MM-DD'), {force:false})
    }   

    return( 
        <>
            <DashboardStats stats={stats}/>
            <DashboardPointsGoal 
                billingPoints={billingPoints} 
                pointsGoalObj={pointsGoalObj} 
                openModal={openModal} 
                weekRangeStr={weekRangeStr}
                prefetchSchedules={prefetchSchedules}
                />
            <DashboardPreferences  
                currentWeekCaseLoad={currentWeekCaseLoad} 
                openModal={openModal}
                prefetchSchedules={prefetchSchedules}
                />
            {
                open && //this should prevent duplicates of requests
                <CaseSchedulingModal 
                    open={true} 
                    closeModal={closeModal} 
                    weekRangeStr={weekRangeStr}
                    mode='scheduling'
                    />

            }
        </>
        
    )

}