import { Box, DialogTitle, Switch } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { ActionButton } from '../../../../../../../../../globalComponents/Buttons/ActionButton/ActionButton';
import { useManageCasesMutation } from '../../../../../../../../rosterAdminAPISlice';
import { useTheme } from '@emotion/react';
import { HaloAsyncObjAutocomplete } from '../../../../../../../../../globalComponents/ReactHookFormFields/AutocompleteFields/HaloAsyncObjAutocomplete';
import { useGetRosterUsersQuery } from '../../../../../../../../rosterAPISlice';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { show400Error } from '../../../../../../../../../../app/errors/genericErrors';
import StaticAlert from '../../../../../../../../../globalComponents/Alert/StaticAlert';
import {useState} from "react"




export default function TargetUserDialog({
    open,
    handleClose,
    selected,
    setSelected,
    selectedRowData
}) {
    const theme = useTheme()
    const [manageCase, { isLoading }] = useManageCasesMutation()
    const { control, handleSubmit } = useForm({
        defaultValues: { user: '' }
    })
    const [sendEmail, setSendEmail] = useState(true)

    const assignCases = ({ user }) => {
        manageCase({
            type: 'cases',
            method: 'PUT',
            body: {
                case_ids: selected,
                user: user?.id,
                notify_by_email: sendEmail
            }
        })
            .unwrap()
            .then((res) => {

                const { message, warning } = res || {}
                setSelected([])
                handleClose()

                if (message) enqueueSnackbar(message, { variant: 'success' })

                if (warning) enqueueSnackbar(warning[0], { variant: 'warning' })

            })
            .catch((err) =>
                show400Error(err)
            )
    }

    const assignedCaseIds = selectedRowData.filter(({ user }) => user).map(({ id }) => id).join(', ')

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth component={'form'} onSubmit={handleSubmit(assignCases)} noValidate>
            <DialogTitle >
                Select clinician
            </DialogTitle>
            <DialogContent>
                <Box className='flex-col' gap={2} >
                    {assignedCaseIds && 
                    <StaticAlert
                        severity={'warning'}
                        title={'Reassignment warning!'}
                        msg={`Case(s) to be reassigned (i.e., already assigned to a clinician): ${assignedCaseIds} `}
                    />}
                    <DialogContentText color={'inherit'}>
                        Select clinician to assign <span style={{ color: theme.palette.primary.main }}>{selected?.length}</span> case(s) to.
                    </DialogContentText>
                    <HaloAsyncObjAutocomplete
                        query={useGetRosterUsersQuery}
                        label='Clinician'
                        name='user'
                        selectOnFocus
                        optionKey='name'
                        control={control}
                    />
                </Box>
                <Box className='flex-row-between-center' p={'4px 16px'}>
                    <DialogContentText
                        color={sendEmail ? 'info' : 'inherit'}
                        sx={(theme) => ({ ...theme.typography.body2 })}
                    >
                        Send assignment email notification
                    </DialogContentText>
                    <Switch checked={sendEmail} onClick={() => setSendEmail(prev => !prev)} />
                </Box>
            </DialogContent>
            <DialogActions>
                <ActionButton
                    variant='outlined'
                    onClick={() => {
                        setSelected([])
                        handleClose()
                    }}
                    disabled={isLoading}>
                    Cancel
                </ActionButton>
                <DialogActions>
                    <ActionButton
                        type='submit'
                        variant='contained'
                        loading={isLoading}
                    >
                        Assign
                    </ActionButton>
                </DialogActions>
            </DialogActions>
        </Dialog>
    )
}