import { Box, Typography } from "@mui/material";
import CustomToolTipContainer from "./CustomTooltipContainer";











export default function CustomNivoTooltip({stats}){
    // ! stats come in { label, value, color } format
    // ! title comes in { title:'', color:'' }


    return(
        <CustomToolTipContainer>
            {
                stats?.map((stat) => (
                    <Box className='flex-row' key={stat?.label} gap={1} alignItems={'center'}>
                        { 
                            stat?.color && <Box backgroundColor={stat?.color} height={8} width={8} borderRadius={'50%'}></Box>
                        }
                        <Typography variant='body3'> {stat?.label} </Typography>
                        <Typography variant="body3">{stat?.value}</Typography>
                    </Box>
                ))
            }
        </CustomToolTipContainer>
    )
}