import { useTheme } from "@emotion/react"
import { Box, Menu, MenuItem, Typography, IconButton, Tooltip, } from "@mui/material"
import { useExtendTrialMutation } from "../../trialAdminAPISlice"
import { formatDateTimeField } from '../../../../utils/dateUtils/formatDateTimeField'
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { useState } from "react";

const timeExtensionPresets = [
    { label: 'One day', value: 1 },
    { label: 'Three days', value: 3 },
    { label: 'One week', value: 7 },
    { label: 'Two weeks', value: 14 }
]

function ExtendTrialMenu({ params }) {

    const theme = useTheme()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const [extendTrial] = useExtendTrialMutation()

    return (
        <>
            <Box className='flex-row-center' height={'100%'} sx={{ justifyContent: 'flex-start' }}>
                <Typography sx={{ ...theme.typography.body2, color: params.row.get_is_trial_over ? 'red' : 'black' }}>
                    {formatDateTimeField(params.value)}
                </Typography>
                {(params.row.get_is_trial_over && !params.row.is_work_completed) &&
                    <Tooltip title='Extend trial period'>
                        <IconButton
                            onClick={handleClick}>
                            <MoreTimeIcon sx={{ color: theme.palette.success.main }} />
                        </IconButton>
                    </Tooltip>}
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >   <Typography sx={{ p: '6px 16px', textDecoration: 'underline', fontWeight: 'bold' }}>Extend by: </Typography>
                {timeExtensionPresets.map((preset) =>
                    <MenuItem
                        value={preset.value}
                        key={preset.label}
                        onClick={() => extendTrial({
                            trial_user_id: params.row.id,
                            days: preset.value
                        })}
                    >
                        {preset.label}
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}

export default ExtendTrialMenu