import { Box } from "@mui/material";
import dayjs from "dayjs";
import { calculateAge } from "../../../../../../utils/dateUtils/calculateAge";
import DetailItem from "../../../../../globalComponents/TextGroups/DetailItem";


function populateStrings(arr, key) {
    return arr.map(item => item[key]).join(", ");
}

export default function CaseDetailPanel({row}){

    const {patient, last_path_review} = row || {}
    const { date_of_birth, zip, next_appt_date, last_appointment_date, medications, icd10_references, city, state, site} =  patient || {}
    return(
        <Box className='flex-col' gap={1}  p={'8px 16px 16px 80px'}>
            <Box className='flex-row' gap={1}>
                <DetailItem 
                    label="Date of Birth" 
                    value={date_of_birth? dayjs(date_of_birth).format("MMM D, YYYY") :null} 
                     />
                <DetailItem 
                    label="Age" 
                    value={calculateAge(date_of_birth)}  />

                <DetailItem 
                    label="Last path review" 
                    value={last_path_review?.date ? dayjs(last_path_review?.date).format("MMM D, YYYY") :null} 
                    link={`/caseload/encounter-details/${last_path_review?.type}/${last_path_review?.encounter_id}`}/>
                <DetailItem 
                    label="Next appt." 
                    value={next_appt_date? dayjs(next_appt_date).format('MMM D, YYYY') : null} 
                     />
                <DetailItem 
                    label="Last appt." 
                    value={last_appointment_date? dayjs(last_appointment_date).format('MMM D, YYYY') :null} 
                     />
            </Box>
            <Box className='flex-row' gap={1}>
                
                <DetailItem
                    label='EMR'
                    value={site?.emr?.name}
                    link={site?.emr?.login_url || null}
                />
                <DetailItem 
                    label='City'
                    value={city}
                />
                <DetailItem 
                    label='State'
                    value={state}
                    
                />
                <DetailItem 
                    label='Zip' 
                    value={zip}  
                    flexBasis={'41%'}
                />
                
            </Box>
            <DetailItem 
                label='Medications' 
                value={populateStrings(medications, 'med_name')}
                />
            <DetailItem 
                label={'Diagnoses'}
                value={populateStrings(icd10_references, 'joint_fields')}
                />

        </Box>
    )


}