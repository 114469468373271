import { useTheme } from "@emotion/react";
import { Box, Typography, MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useCallback, useEffect, useState } from "react";
import ResponsiveBarChart from "../../../globalComponents/NivoCharts/ResponsiveBar/ResponsiveBarChart";
import ResponsiveLineChart from "../../../globalComponents/NivoCharts/ResponsiveLine/ResponsiveLineChart";
import { useGetDashboardChartsDataQuery } from "../../dashboardApiSlice";
import dayjs from "dayjs";
import { groupByTimeFrame } from "../../utils";
import HaloSkeleton from "../../../globalComponents/Skeletons/HaloSkeleton";
import EmptyChartPlaceholder from "../../../globalComponents/NivoCharts/EmptyChartPlaceholder";
import CustomNivoTooltip from "../../../globalComponents/NivoCharts/Tooltips/CustomNivoTooltip";

const chartInit = {
    chart_data:[
            {
                "id": "",
                "data": [{x:'', y:''}]
            },
        ],
    start_date:'',
    end_date:''
}

const timePeriodOptions = [
    {label: 'Monthly', value: 'monthly'},
    {label: 'Weekly', value: 'weekly'},
    {label: 'Daily', value: 'daily'}
]

const DropDownSelect = ({timePeriod, stateSetterFunc}) => {
    const handleTimePeriodChange = (event) => {
        stateSetterFunc(event.target.value)
    }

    return (
        <Select
            value={timePeriod}
            onChange={handleTimePeriodChange}
            IconComponent={KeyboardArrowDownIcon}
            sx={{fontSize:14, 
                '& .MuiInputBase-input':{padding: 0},
                '& .MuiOutlinedInput-notchedOutline': {
                border: 'none', }}}
        >
            {timePeriodOptions.map((period, index) => <MenuItem key={index} value={period.value} sx={{fontSize:'14px'}}>{period.label}</MenuItem> )}
        </Select>
    )
}


const now = dayjs();
const today = now.format('YYYY-MM-DD')

export default function Benchmarking(){
    
    const theme = useTheme();
    const [formattedCaseloadChartData, setFormattedCaseloadChartData] = useState(chartInit);
    const [formattedPointsChartData, setFormattedPointsChartData] = useState(chartInit);
    const [caseloadChartPeriod, setCaseloadChartPeriod]= useState('daily')
    const [pointsChartPeriod, setPointsChartPeriod] = useState('daily')

    const {data: caseloadChartData, isLoading: isCaseloadLoading, isFetching: isCaseloadFetching } = useGetDashboardChartsDataQuery({chartType: 'caseload', today, chartPeriod: caseloadChartPeriod})
    const {data: pointsChartData , isLoading: isPointsLoading, isFetching: isPointsFetching} = useGetDashboardChartsDataQuery({chartType: 'billing', today, chartPeriod: pointsChartPeriod})

    //formats the chart data based on time period view
    const formatChartData =  useCallback((chartPeriod, {chart_data, ...dateStrings}, stateSetterFunc) => {
        if(!chart_data) return;

        const formattedChartData = chart_data.map((data) => {return {...data}})
        formattedChartData.forEach((dataObject) => {
            const result = groupByTimeFrame(dataObject.data, chartPeriod)
            dataObject.data = result
        })
        stateSetterFunc({
            chart_data:formattedChartData,
            ...dateStrings
        })
    }, [])

    useEffect(() => {
        if (!caseloadChartData) return
        formatChartData(caseloadChartPeriod, caseloadChartData, setFormattedCaseloadChartData)
    }, [caseloadChartPeriod, formatChartData, caseloadChartData]);
    

    useEffect(() => {
        if (!pointsChartData) return 
        formatChartData(pointsChartPeriod, pointsChartData, setFormattedPointsChartData)
    },[pointsChartPeriod, formatChartData, pointsChartData])


    const isCaseloadDataEmpty = (chartState) => chartState.end_date ? false : true

    const isBillingPointDataEmpty = (chartState) => {
        if (!chartState.end_date) return true
        const containsGreaterThanZero = !chartState.chart_data[0].data.some((val) => val.y > 0)
        return containsGreaterThanZero
    }

    

    return(
        <Box className='flex-col' gap={2}>
            <Typography variant="h6">Benchmarking</Typography>
            <Box className='flex-row' gap={2}>
                    <Box className='flex-col' sx={{...theme.standardBox,flex:1, height:230,  p:2, pb:3, gap:2, minWidth:0}}>
                        <Box className='flex-row-between'>
                            <Box className='flex-col'>
                                <Typography variant="subtitle2"> Caseload performance </Typography>
                                <HaloSkeleton loading={isCaseloadLoading || isCaseloadFetching}>
                                    <Typography variant="body2" color='text.disabled'>{ formattedCaseloadChartData?.start_date + ' - ' + formattedCaseloadChartData?.end_date}</Typography>
                                </HaloSkeleton>
                            </Box>
                            <DropDownSelect timePeriod={caseloadChartPeriod} stateSetterFunc={setCaseloadChartPeriod}/>
                        </Box>
                        <Box height={150} className='flex-col-center'>
                            <HaloSkeleton loading={isCaseloadLoading || isCaseloadFetching} variant='rounded' width={'99%'} height={'70%'}>
                                {isCaseloadDataEmpty(formattedCaseloadChartData) ? <EmptyChartPlaceholder width={315}/> : <ResponsiveLineChart data={formattedCaseloadChartData?.chart_data} theme={theme}/>}                                
                            </HaloSkeleton>
                        </Box>
                    </Box>

                    <Box className='flex-col' sx={{...theme.standardBox, flex:1, height:230, p:2, pb:3, gap:2, minWidth:0}}>
                        <Box className='flex-row-between'>
                            <Box className='flex-col'>
                                <Typography variant="subtitle2"> Billing points </Typography>
                                <HaloSkeleton loading={isPointsLoading || isPointsFetching}>
                                    <Typography variant="body2" color='text.disabled'>{ formattedPointsChartData?.start_date + ' - ' + formattedPointsChartData?.end_date}</Typography>
                                </HaloSkeleton>
                            </Box>
                            <DropDownSelect timePeriod={pointsChartPeriod} stateSetterFunc={setPointsChartPeriod}/>
                        </Box>
                        <Box height={150} className='flex-col-center'>
                            <HaloSkeleton loading={isPointsLoading || isPointsFetching} variant='rounded' width={'99%'} height={'70%'}>
                                {
                                isBillingPointDataEmpty(formattedPointsChartData) ? 
                                    <EmptyChartPlaceholder width={315}/> : 
                                    <ResponsiveBarChart 
                                        data={formattedPointsChartData?.chart_data[0].data} 
                                        legend={{
                                            data:[{
                                            color: theme.palette.primary.dark,
                                            id: 'Points',
                                            label:'Points'
                                        }]}}
                                        customTooltipFunc={obj => (
                                            <CustomNivoTooltip 
                                                stats={[{
                                                    label:'points',
                                                    value: obj.data.y,
                                                    color: 'rgb(0, 111, 169)'
                                                }]}/>
                                        )}/>
                                }
                            </HaloSkeleton>
                        </Box>
                    </Box>
            </Box>
        </Box>
    )
}
