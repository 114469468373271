import { DataGridPro} from "@mui/x-data-grid-pro";
import { useGetUsersCaseStatsQuery } from "../../../../../../rosterAdminAPISlice";
import { Box, Typography } from "@mui/material";
import UserAvatar from "../../../../../../../globalComponents/Avatar/UserAvatar";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import KeepMountedTabPanel from "../../../../../../../globalComponents/Tabs/TabPanels/KeepMountedTabPanel";
import { hideSelectAllCheckBoxProps, linkedRowProps } from "../../../../../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps";
import CliniciansToolbar from "./CliniciansToolbar";





const columns = [
    {
        field: 'name',
        headerName: 'Name',
        flex:1,
        renderCell: ({value, row}) => (
            <Box className='flex-row' alignItems={'center'} gap={1} height='100%'>
                {/* <Avatar variant="circular" sx={{bgcolor:'success.main', height:24, width:24}}>
                    <Typography variant="caption">
                        {value?.slice(0, 1)?.toUpperCase()}
                    </Typography>
                </Avatar> */}
                <UserAvatar 
                    firstName={value} 
                    imgUrl={row?.google_profile_photo} 
                    sx={{bgcolor:'success.main', height:24, width:24}}
                    typographyVariant='caption'
                    />
                <Typography 
                    variant="body2" 
                    noWrap 
                    title={value}
                    >{value}</Typography>
            </Box>
        )
    },
    {
        field: 'requested',
        headerName: 'Requested',
        flex:1
    },    
    {
        field: 'assigned',
        headerName: 'Assigned',
        flex:1
    },    
    {
        field: 'completed',
        headerName: 'Completed',
        flex:1
    },    
    {
        field: 'completion_percentage',
        headerName: 'Comp. rate',
        flex:1,
        valueFormatter: (value) => {
            
            if (!value) return '-'
            
            else{
                return value.toFixed(2) + '%'
            }
        }
    },
]


const getRowId = (row) => row.user_id



export default function RMCliniciansGrid({
    gridProps,
    dates, 
    pageSize, 
    pageSizeOptions, 
    setPageSize,
    apiRef,
    visible,
}){

    const navigate = useNavigate()
    const [paginationModel, setPagination] = useState({ pageSize, page:0 })
    const {data, isLoading, isFetching} = useGetUsersCaseStatsQuery(dates, {skip: !visible})
    const [selected, setSelected] = useState([])
    const isSelected = selected?.length > 0

    useEffect(() => {

        setPagination(prev => ({...prev, pageSize}))

    }, [pageSize])

    const handleCellClick = ({field, row}) =>{
        
        if (field.includes('__') || field === 'actions') return

        else{
            navigate('/admin/clinician/' + row.user_id)
        }
    }

    const handlePaginationChange = (pagination) => {
        setPagination(pagination)
        setPageSize(pagination?.pageSize)
    }

    const handleRowSelection = (selectedRowsModel) => setSelected(selectedRowsModel)


    return(
        <KeepMountedTabPanel className={visible ? 'visible' : ''}>
            <DataGridPro 
                apiRef={apiRef}
                rows={data || [] }
                columns={columns}
                loading={isLoading || isFetching}
                getRowId={getRowId}
                pagination
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationChange}
                pageSizeOptions={pageSizeOptions}
                checkboxSelection
                disableRowSelectionOnClick
                rowSelectionModel={selected}
                onRowSelectionModelChange={handleRowSelection}
                onCellClick={handleCellClick}
                columnHeaderHeight={isSelected? 0 : 52}
                {...gridProps}  
                sx={{
                    ...gridProps.sx,
                    ...hideSelectAllCheckBoxProps,
                    ...linkedRowProps
                }}
                slots={{
                    ...gridProps?.slots,
                    toolbar: CliniciansToolbar
                }}
                slotProps={{
                    toolbar:{isSelected, selected, setSelected}
                }}
                />
        </KeepMountedTabPanel>
    )
}