import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro"
import { useGetSitesQuery } from "../../../../../../patients/patientAPISlice"
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import PatientIntakeModal from "../../../../../../dataIntake/components/PatientIntakeModal/PatientIntakeModal";
import { useNavigate } from "react-router-dom";
import KeepMountedTabPanel from "../../../../../../globalComponents/Tabs/TabPanels/KeepMountedTabPanel";
import { linkedRowProps } from "../../../../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps";




export default function RMSitesGrid({
    gridProps,
    pageSizeOptions, 
    pageSize,
    setPageSize,
    apiRef,
    visible
}){


    const navigate = useNavigate()
    const { data, isLoading, isFetching } = useGetSitesQuery(undefined, {skip: !visible})
    const [paginationModel, setPagination] = useState({ pageSize, page:0 })
    const [ intakeSite, setIntakeSite ] = useState({})
    const [ open, setOpen ] = useState(false)

    const columns =[
        {
            field: 'site_name',
            headerName: 'Site',
            flex:1
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 100,
            cellClassName: 'actions',
            getActions: ({row}) =>{
    
                return [
                    <Tooltip title='Upload patients'>
                        <GridActionsCellItem 
                            icon={<UploadFileIcon />}
                            label="Upload patients"
                            onClick={() => {
                                setIntakeSite(row)
                                setOpen(true)
                            }}
                            />
                    </Tooltip>
                ]
            }
        }
    ]

    const handleCellClick = ({field, row}) =>{
        
        if (field.includes('__') || field === 'actions') return

        else{
            navigate('/admin/site/' + row.id)
        }
    }

    const handlePaginationChange = (pagination) => {
        setPagination(pagination)
        setPageSize(pagination?.pageSize)
    }   

    useEffect(() => {

        setPagination(prev => ({...prev, pageSize}))

    },[ pageSize])

    
    return (
        <>
            <KeepMountedTabPanel className={visible ? 'visible' : ''}>
                <DataGridPro
                    rows={data || []}
                    columns={columns}
                    apiRef={apiRef}
                    disableRowSelectionOnClick
                    pagination
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationChange}
                    pageSizeOptions={pageSizeOptions}
                    onCellClick={handleCellClick}
                    {...gridProps}
                    sx={{
                        ...gridProps.sx,
                        ...linkedRowProps
                    }}
                />
            </KeepMountedTabPanel>
            <PatientIntakeModal open={open} setOpen={setOpen} intakeSite={intakeSite}/>
        </>
    )
}