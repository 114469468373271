import OutlinedToolbarChip from "../../../../../../../globalComponents/Chips/OutlinedToolbarChip/OutlinedToolbarChip";
import { AssignmentIcon } from '../../../../../../../../assets/icons/MaterialDesignIcons';
import { useState } from "react";
import NumCasesDialog from "./NumCasesDialog";
import StickyHeaderToolbar from "../../../../../../../globalComponents/DataGrid/subComponents/toolBars/StickyHeaderToolbar";



export default function CliniciansToolbar({
    isSelected,
    selected,
    setSelected
}){

    const [open, setOpen] = useState(false)

    const handleClose = (event, reason) => {
        if(reason === 'backdropClick') return
        setOpen(false)
    }
    const handleOpen = () => setOpen(true)
   

return(
    isSelected &&
            <>
            <StickyHeaderToolbar sx={{height:52}}>
                <OutlinedToolbarChip
                onClick={handleOpen}
                startIcon={<AssignmentIcon sx={{ height:18, width:18 }}/>}>Assign</OutlinedToolbarChip>
            </StickyHeaderToolbar>
            <NumCasesDialog 
                open={open}
                handleClose={handleClose}
                selected={selected}
                setSelected={setSelected}
            />
            </>
)
}