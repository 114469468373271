import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from '@emotion/react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useEffect, useRef, useState } from 'react';
import { ActionButton } from '../../../../../../globalComponents/Buttons/ActionButton/ActionButton';


export default function FilterMenu({
    setFilter,
    loadMoreHistory,
    disabled=false
}) {
  const theme = useTheme()
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false)
  
  const handleFilterChange = (newFilter) =>{
    setFilter(newFilter)
    loadMoreHistory({newFilter, page:1}) //still have to pass parameters due to react's async nature
  }   
  
  
  const handleToggle = () => setOpen((prevOpen) => !prevOpen)
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const handleMenuClick = (e,filter) =>{
      handleFilterChange(filter)
      handleClose(e)
    }

  

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);


  return (
      <Box>
        <ActionButton
          ref={anchorRef}
          onClick={handleToggle}
          disabled={disabled}
          sx={{color:theme.palette.action.active}}
          endIcon={<FilterListIcon sx={{height:20, width:20}}/>}
          >
          Filter by
        </ActionButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{zIndex:2}}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
              >
                <Paper >
                  <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                          autoFocusItem={open}
                          onKeyDown={handleListKeyDown}
                          sx={{borderRadius:1}}>
                          <MenuItem onClick={(e) => handleMenuClick(e, 'All')}>All</MenuItem>
                          <MenuItem onClick={(e) => handleMenuClick(e, 'Labs')}>Lab document</MenuItem>
                          <MenuItem onClick={(e) => handleMenuClick(e, 'Pathology Encounter')}>Pathology encounter</MenuItem>
                          <MenuItem onClick={(e) => handleMenuClick(e, 'Infectious Disease Encounter')}>Infectious disease encounter</MenuItem>
                  </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
      </Box>
  );
}
