import { useForm } from "react-hook-form";
import { Box } from "@mui/material"
import ModalWithHaloLogo from "../../auth/components/ModalWithHaloLogo";
import { useLazyGetReferralInfoQuery, useSignUpTrialUserMutation } from "../trialUserAPISlice";
import { useParams, useNavigate } from "react-router-dom"
import { useCallback, useEffect, useRef, useState } from "react";
import { show400Error } from "../../../app/errors/genericErrors";
import SignUpInfoFields from "../components/TrialSignUp/SignUpInfoFields"
import ResumeUpload from "../components/TrialSignUp/ResumeUpload";
import InvalidTrialLink from "./InvalidTrialLink";
import { ActionButton } from "../../globalComponents/Buttons/ActionButton/ActionButton";
import { stringToTitleCase } from "../../../utils/stringUtils/titleCase";
import { last } from "lodash";


function TrialSignUp() {

    const navigate = useNavigate()

    const [resumeStep, setResumeStep] = useState(false)

    const [isLinkValid, setIsLinkValid] = useState(true)

    const { signedPayload } = useParams()

    const [getReferralInfo] = useLazyGetReferralInfoQuery()

    const [referralInfo, setReferralInfo] = useState(null)

    useEffect(() => {
        getReferralInfo(signedPayload)
            .unwrap()
            .then((res) => setReferralInfo(res))
            .catch(err => console.log(err))
    }, [])

    const [signUp, { isLoading: isSigningUp }] = useSignUpTrialUserMutation()

    const { control, handleSubmit, formState: { errors, isValid }, reset, getValues, setValue, watch, register, trigger } = useForm({
        defaultValues: {
            first_name: '',
            last_name: '',
            email: '',
            password1: '',
            password2: '',
            phone: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            resume_file: null
        }
    })

    useEffect(() => {
        setValue('email', referralInfo?.email)
    }, [referralInfo])

    useEffect(() => {
        if (referralInfo && referralInfo?.invalid_link) {
            setIsLinkValid(false)
        }
    }, [referralInfo])

    const password1 = watch('password1')

    const handleSignUp = async () => {
        getReferralInfo(signedPayload)
            .unwrap()
            .then((res) => setReferralInfo(res))

        let valid = await trigger()
        if (!valid) return;

        if (isValid) {
            setResumeStep(true)
        }

        if (resumeStep) {
            const formData = new FormData()
            setValue('first_name', stringToTitleCase(getValues('first_name')))
            setValue('last_name', stringToTitleCase(getValues('last_name')))
            setValue('address', `${getValues('address')} ${getValues('address_2')}`)
            const values = getValues()
            Object.keys(values).forEach((key) => formData.append(key, values[key]))
            formData.append('signed_payload', signedPayload)
            formData.append('preferred_title', stringToTitleCase(`${getValues('first_name')} ${getValues('last_name')}`))
            await signUp(formData)
                .unwrap()
                .then(() => {
                    navigate('/login', { state: { fromSignUp: true } })
                })
                .catch((err) => show400Error(err))
        }
    }

    const submitButtonRef = useRef(null)

    const handleKeyDown = useCallback((event) => {
        if(event.key === 'Enter') {
            if (submitButtonRef.current && resumeStep) {
                submitButtonRef.current.click()
            }
        }
    })

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)
        return () => window.removeEventListener('keydown', handleKeyDown)
    },[handleKeyDown])

    return (
        <>
            {isLinkValid ?
                (<ModalWithHaloLogo>
                    <Box className='flex-col' width={'100%'} gap={2} component='form' onSubmit={handleSubmit(handleSignUp)} noValidate>
                        {!resumeStep ?
                            <SignUpInfoFields
                                control={control}
                                password1={password1}
                                register={register}
                                errors={errors}
                                onSignUp={handleSignUp}
                                setValue={setValue}
                                getValues={getValues}
                            /> :
                            <ResumeUpload setValue={setValue} getValues={getValues} />
                        }
                        <ActionButton disabled={isSigningUp} width={'100%'} variant='contained' type='submit' ref={submitButtonRef}>{resumeStep ? 'Create an account' : 'Continue'}</ActionButton>
                    </Box>
                </ModalWithHaloLogo>) :
                <InvalidTrialLink />
            }
        </>
    )
}

export default TrialSignUp
