import { LoadingButton } from "@mui/lab";
import { Box, Divider, Typography, Tooltip, Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RequestCasesDialog from "./RequestCasesDialog";
import BreadCrumbHeader from "../../../globalComponents/Headers/BreadCrumbHeader";
import { selectIsReqCaseDialogOpen, setIsReqCaseDialogOpen, selectEncounterHistoryFilters, setEncounterHistoryFilters } from "../../rosterSlice";
import { useDispatch, useSelector } from "react-redux";
import DateRangeButton from "../../../globalComponents/DateRangePicker/DateRangeButton";
import { useLazyGetBillingStatsSummaryQuery } from "../../../billing/billingApiSlice";
import { useEffect, useState } from "react";
import HaloSkeleton from "../../../globalComponents/Skeletons/HaloSkeleton";
import { TooltipInfoIcon } from "../../../../assets/icons/MaterialDesignIcons";


const tooltipMessage = "Cases undergoing QA review will have points counted towards the date of their approval, not the completion date."

export default function CaseLoadHeader({ dailySchedule, tabValue }) {


    const dispatch = useDispatch()
    const dialogOpen = useSelector(selectIsReqCaseDialogOpen)

    const setOpen = (bool) => (e) => dispatch(setIsReqCaseDialogOpen(bool))

    const { start_date, end_date } = useSelector(selectEncounterHistoryFilters)

    const [getBillingStats, { isLoading, isFetching }] = useLazyGetBillingStatsSummaryQuery()

    const [billingPoints, setBillingPoints] = useState(null)
    const [completedCases, setCompletedCases] = useState(null)

    useEffect(() => {
        if (start_date) {
            getBillingStats({ start_date, end_date })
                .unwrap()
                .then((res) => {
                    setBillingPoints(res.billing_points)
                    setCompletedCases(res.completed_cases_count)
                })
        }

    }, [start_date, end_date])

    const metricsHeader = () => {
        if (tabValue === 'Caseload') {
            return (
                <>
                    <Box className='flex-row' gap={'12px'}>
                        <Typography variant="body2" title='Requested cases'>{dailySchedule?.requested_cases || '-'} Requested</Typography>
                        <Divider style={{ borderWidth: 1 }} />
                        <Typography variant="body2" title='Assigned cases'>{dailySchedule?.assigned_cases || '-'} Assigned</Typography>
                        <Divider style={{ borderWidth: 1 }} />
                        <Typography variant="body2" title='Completed cases'>{dailySchedule?.completed_cases || '-'} Completed</Typography>
                    </Box>
                    <LoadingButton
                        variant="outlined"
                        loadingPosition="start"
                        title="Request additional cases"
                        onClick={setOpen(true)}
                        startIcon={<AddIcon />}
                        sx={{
                            borderRadius: '18px'
                        }}>
                        Cases
                    </LoadingButton>
                </>
            )
        }

        else if (tabValue === 'History' && start_date) {
            return (
                <>
                    <Box className='flex-row-center' gap={'12px'}>
                        <HaloSkeleton loading={isLoading || isFetching} height={24}>
                            <Box className='flex-row-center' gap={'4px'}>
                                <Typography variant="body2" title='Billing points'>{billingPoints || '-'} Billing points</Typography>
                                <Tooltip arrow title={tooltipMessage}>
                                    <Box className='flex-row align-center'> 
                                        <TooltipInfoIcon style={{ cursor: 'pointer', fontSize:20 }} color='action'/>
                                    </Box>
                                </Tooltip>
                            </Box>
                        </HaloSkeleton>
                        <Divider style={{ borderWidth: 1 }} flexItem />
                        <HaloSkeleton loading={isLoading || isFetching} height={24}>
                            <Typography variant="body2" title='Submitted cases'>{completedCases || '-'} Completed</Typography>
                        </HaloSkeleton>
                    </Box>
                </>
            )
        }

    }

    return (
        <>
            <RequestCasesDialog open={dialogOpen} handleClose={setOpen(false)} />
            <BreadCrumbHeader
                title='My Cases'
                helperText={tabValue === 'Caseload' ? "Today's assigned cases are listed below" : 'Completed cases are listed below'}
                crumbs={[tabValue]}
                endComponent={
                    <Box className='flex-row' gap={3} alignItems={'center'}>
                        {metricsHeader()}
                        {tabValue === 'History' ?
                            <DateRangeButton
                                storedDateRangeSelector={selectEncounterHistoryFilters}
                                setDateRangeReducer={setEncounterHistoryFilters}
                                buttonTitle={'Billing stats'}
                            /> : null}

                    </Box>
                }
            />
        </>
    )
}
