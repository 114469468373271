import { Alert, AlertTitle, Box, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useWatch } from "react-hook-form";
import { useLazySearchForPatientDupesQuery } from "../../../patientAPISlice";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { ActionButton } from "../../../../globalComponents/Buttons/ActionButton/ActionButton";
import HaloModal from "../../../../globalComponents/Modals/HaloModal";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { Link } from "react-router-dom";
import { stringToTitleCase } from "../../../../../utils/stringUtils/titleCase";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDispatch } from "react-redux";
import { setPatientListFilters } from "../../../../admin/adminSlice";
import  HaloSkeleton  from '../../../../globalComponents/Skeletons/HaloSkeleton'



export default function DupeAlert({control, handleParentModalClose}){
    
    const dispatch = useDispatch()
    const [open , setOpen] = useState(false)    
    const [searchDupes, { data, isLoading, isFetching}] = useLazySearchForPatientDupesQuery()
    const [firstName, lastName, dateOfBirth] = useWatch({
        control,
        name: ['first_name', 'last_name', 'date_of_birth']
    })

    const debouncedSearchDupes = useCallback(
        debounce((params) => {
            searchDupes(params, {preferCacheValue:false});
        }, 300),
        [searchDupes]
    );

    useEffect(() => {

        if( firstName && lastName && dayjs(dateOfBirth).isValid() ){
            debouncedSearchDupes({ 
                firstName, 
                lastName, 
                dateOfBirth: dayjs(dateOfBirth).format('YYYY-MM-DD') 
            });
        }
    }, [firstName, lastName, dateOfBirth, debouncedSearchDupes])


    useEffect(() => {
        return () => {
            debouncedSearchDupes.cancel();
        };
    }, [debouncedSearchDupes]);

    const CustomLink = forwardRef(({ touchRippleRef, ...props }, ref) => (
        <Link ref={ref} {...props} />
      ));

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width:88,
        },
        {   
            field: 'first_name',
            headerName: 'Name', 
            flex:1,
            valueGetter:(value,row) => { //need to do this to allow for proper backend sorting
                return stringToTitleCase(row.name) || '-'
            }
        },
        {
            field: 'date_of_birth',
            headerName: 'Date of Birth',
            flex:1,
            valueGetter:(value,row) => {
                if (!value) return '-'
    
                return dayjs(value).format('MMM D, YYYY') 
            }
        },
        {
            field: 'site_name',
            headerName: 'Site',
            flex:1,
            valueGetter: (value) => value || '-'
        },
        {
            field:'last_path_review',
            headerName: 'Last path review',
            flex:1,
            renderCell: ({row}) => {
                const {last_path_review} = row || {}
                return(<Box className='flex-row' 
                    sx={{ 
                        height:'100%', 
                        width:'100%', 
                        alignItems:'center'
                        }}>
    
                        {
                            last_path_review?.date ? 
                            <Link className='flex-row' target="_blank" style={{ textDecoration: 'none', height:'100%', width:'100%', alignItems:'center' }} to={`/caseload/encounter-details/${last_path_review?.type}/${last_path_review?.encounter_id}`} title={dayjs(last_path_review?.date).format("MMM D, YYYY")}>
                                <Typography variant='body2' color={'primary'}>{dayjs(last_path_review?.date).format("MMM D, YYYY")}</Typography>
                            </Link>
                            :
                            <Typography variant='body2'>-</Typography>
                        }
                </Box>)
            }
        },
        {
            field:'actions',
            type: 'actions',
            headerName: '',
            width: 100,
            getActions: ({id}) => {
                return[
                    <Tooltip title='Search in patient list' enterDelay={0}>
                        <GridActionsCellItem 
                            icon={<PersonSearchIcon />}
                            label='Search in patient list'
                            onClick={() =>{
                                dispatch(setPatientListFilters({search:id}))
                                handleParentModalClose()
                            }}
                        />
                    </Tooltip>,
                    <Tooltip title='View profile' enterDelay={0}>
                        <CustomLink to={'/patients/profile/' + id} target="_blank" >
                                <GridActionsCellItem 
                                    icon={ <AccountCircleIcon/> }
                                    label='View profile'
                                />
                            
                        </CustomLink>
                    </Tooltip>
                ]
            }
        }
        
    ]


    return(
        <>
            <Alert
                severity={data?.length>0 ? 'error' : 'success'}
                action={data?.length>0 &&
                    <ActionButton color='inherit' onClick={() =>setOpen(true)}>View</ActionButton>
                }>
                <HaloSkeleton loading={isFetching || isLoading}>
                    <AlertTitle>Possible duplicate profiles found · {data?.length || 0}</AlertTitle>
                </HaloSkeleton>
            </Alert>
        <HaloModal 
            keepMounted
            open={open}
            title='Profiles found'
            modalMaxWidth={900}
            handleClose={() =>setOpen(false)}>
                <Box sx={(theme) =>({...theme.standardBox})} m={3}>
                    <DataGridPro 
                        rows={data || []}
                        columns={columns}
                        hideFooter
                        autoHeight
                        disableRowSelectionOnClick
                        sx={{border:'none'}}
                        />
                </Box>
        </HaloModal>
        </>
    )
}