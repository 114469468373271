import { Box, Chip, Tooltip } from "@mui/material"
import { memo } from "react"
import ChatIcon from '@mui/icons-material/Chat';
import { stringToTitleCase } from "../../../../utils/stringUtils/titleCase";


const MDApprovedStatusChip = ({mdApproved, qaObj=null}) => {
    const {review_state_string} = qaObj || {}


    const status = mdApproved ? 'Completed' : 'Submitted'
    const qaStatus = stringToTitleCase(review_state_string)

    return(
        <Box className='flex-row' gap={1} height={'100%'} alignItems='center'>
            <Chip label={status} size='small' color={mdApproved? 'success' : 'warning'} />
            {
                (qaStatus === 'Rejected' || qaStatus === 'Approved') &&
                <Tooltip title={'Case has QA feedback - ' + qaStatus + ' ' + qaObj?.approval_date || '' }>
                    <ChatIcon color='action'/>
                </Tooltip>
            }
            
        </Box>
    ) 
}


export default memo(MDApprovedStatusChip)
