import {useTheme} from "@emotion/react"
import { Box, Button, IconButton, Modal, Typography, Divider, TextField} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from "react";
import dayjs from "dayjs"
import { useFormik} from "formik"
import CloseIcon from '@mui/icons-material/Close';
import { useUploadLabDocsMutation } from "../../rosterAPISlice";
import uploadModalGraphic from '../../../../assets/icons/upload-modal-graphic.svg'
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentCase } from "../../rosterSlice";
import FileDropzone from "./FileDropzone";
import {enqueueSnackbar} from 'notistack'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { apiSlice } from "../../../../app/api/apiSlice";
import { useParams } from "react-router-dom";

const LabDocUploadModal = ({open, setOpen, setAllLabs, allLabs}) => {

const theme = useTheme()
const {caseId} = useParams()
const dispatch = useDispatch()

const [uploadDoc] = useUploadLabDocsMutation()

const handleClose = () => {
    resetForm()
    setOpen(false)
}

const handleFileUpload = (file) => {
    setFieldValue('documentation', file[0])
}

const {patient} = useSelector(selectCurrentCase)

const initValues = {
    result_summary: '',
    result_date: dayjs(),
    documentation: null,
}

const formik = useFormik({
    initialValues:initValues,
    validate: (values) => {
        const errors = {}

        if(!values.result_summary) {
            errors.result_summary = 'Result summary is required' 
        }

        if(!values.result_date) {
            errors.result_date = 'Result date is required'
        }

        if(values.result_date && values.result_date > new Date()) {
            errors.result_date = 'Future dates can not be selected'
        }

        if(!values.documentation) {
            errors.documentation = 'File is required'
        }

        return errors
    },
    onSubmit: (values) => {
        const formData = new FormData()
        //iterates through each of the init value keys and appends the formik values to the formData object
        values.result_date = values.result_date.format('YYYY-MM-DD')
        Object.keys(initValues).forEach((key) => formData.append(key, values[key]))
        formData.append('patient_id', patient.id)
        uploadDoc(formData)
        .unwrap()
        .then((res) => {
            setAllLabs((prevLabs) => ({
                ...prevLabs,
                data: [{data: {...res.new_doc}, type:'Documentation'}, ...prevLabs.data],
                total_items: prevLabs.total_items + 1
            }))
            dispatch(apiSlice.util.updateQueryData('getCase', caseId, (caseData)=> {
                caseData.paginated_lab_history = {
                    ...allLabs,
                    data: [{data: {...res.new_doc}, type: 'Documentation'}, ...allLabs.data,],
                    total_items: allLabs.total_items + 1
                }
            }))
            enqueueSnackbar(res?.message, {variant:'success'})
        })
        .catch((err) => {
            enqueueSnackbar('Error uploading file, please try again', {variant:'error'})
        })

        handleClose()
    },
    validateOnMount:false,
})

const {values, handleChange, setFieldValue, handleSubmit, resetForm} = formik

const [docError, setDocError] = useState(false)

useEffect(() => {
    if (formik.touched.documentation && Boolean(formik.errors.documentation)) {
        setDocError(true)
    }
    else {
        setDocError(false)
    }
},[formik.errors.documentation, formik.touched.documentation])

return (
    <Modal
    open={open}
    >
        <Box className='flex-col' sx={{...theme.standardBox, maxWidth:440, ...theme.modal.centered}}>
            <Box className="flex-row-between-center" sx={{ justifyContent: 'space-between', p: '8px 16px' }}>
                <Typography variant="subtitle2">Lab document upload</Typography>
                <IconButton size="small" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider/>

            <Box className='flex-col' gap={2} p={2}>
                <Box className='flex-row-center' gap={2} p={'16px 0px'}>
                    <img src={uploadModalGraphic} alt='upload graphic' width={66.39} height={72}/>
                </Box>

                <form onSubmit={handleSubmit} encType="multipart/form-data" noValidate> 

                    <Box className='flex-col' gap={2}>

                            <TextField
                                required
                                label='Lab result summary' 
                                name='result_summary' 
                                size='small' 
                                InputProps={{sx:{fontSize:'14px'}}}
                                InputLabelProps={{sx: {fontSize: "14px"}}} 
                                value={values.result_summary} 
                                onChange={handleChange}
                                error={formik.touched.result_summary && Boolean(formik.errors.result_summary)}
                                helperText={formik.touched.result_summary && Boolean(formik.errors.result_summary) ? formik.errors.result_summary : ''}
                            />

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                slotProps={{ 
                                    textField: { 
                                        helperText: formik.touched.result_date && Boolean(formik.errors.result_date) ? formik.errors.result_date : '',
                                        required: true, 
                                        size:'small', 
                                        InputProps: {sx:{fontSize:'14px'}}, 
                                        name: 'result_date',
                                        InputLabelProps: {sx:{fontSize:'14px'}}, 
                                        error: formik.touched.result_date && Boolean(formik.errors.result_date)                    
                                    }}}
                                    slots={{openPickerIcon: CalendarMonthIcon}}
                                    reduceAnimations={true}
                                    label='Lab result date'
                                    value={values.result_date}
                                    onChange={(date) => setFieldValue('result_date', dayjs(date))}
                                    disableFuture
                                />
                            </LocalizationProvider>

                            <FileDropzone onFileUpload={handleFileUpload} file={values.documentation} setFieldValue={setFieldValue} docError={docError} docErrorMsg={formik.errors.documentation}/>
                    </Box>

                    <Box className='flex-row' gap={1} sx={{ justifyContent: 'flex-end', alignItems: 'center', p: '16px 0px 0px 8px' }}>
                            <Button variant='text' sx={{color: theme.palette.text.primary}} onClick={handleClose}>Cancel</Button>
                            <Button variant='contained' type='submit' disableElevation sx={{borderRadius: '18px', height: 34, width: 63}}>Save</Button>
                    </Box>                    
                </form>
            </Box>
        </Box>

    </Modal>
)
}

export default LabDocUploadModal
