import { Chip, Tab } from "@mui/material";
import { PatientListFillIcon } from "../../../../assets/icons/MaterialDesignIcons";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { ContentPaste } from "@mui/icons-material";
import { usePrefetchEndpoint } from "../../../../utils/apiUtils/usePrefetchEndpoint";
import HaloTabs from "../../../globalComponents/Tabs/HaloTabs";
import HaloTabLabel from "../../../globalComponents/Tabs/TabLabels/HaloTabLabel";


export default function AdminTabs({tabValue, handleTabChange, userGroups}){


    return(
        <HaloTabs
            value={tabValue}
            onChange={handleTabChange}
            scrollButtons
            allowScrollButtonsMobile
            variant="scrollable"
            >
            {
                userGroups?.['patient list'] &&
                    <Tab 
                        label={<HaloTabLabel label='Patients'/>}
                        value='Patients'
                        icon={<PatientListFillIcon color='primary' sx={{height:20, width:20}}/>}
                        iconPosition="start"
                        
                    />
            }
            {
                userGroups?.['QA | Reviewer'] &&
                <Tab 
                    label={<HaloTabLabel label='QA cases'/>}
                    value={'QA cases'}
                    icon={<DescriptionOutlinedIcon color="warning" sx={{fontSize:20}}/>}
                    iconPosition="start"
                />
            }
            {   (userGroups?.['Trial User | Reviewer'] || userGroups?.['Trial User | Admin'] || userGroups?.['Developer']) &&
                <Tab
                    label={<HaloTabLabel label='Trial users'/>}
                    value='Trial users'
                    icon={<ContentPaste color='primary' sx={{height:20, width:20}}/>}
                    iconPosition="start"
                    
                />
            }
            {
                (userGroups?.['Developer'] || (userGroups?.['Internal Staff'] && userGroups?.['Roster Management | Admin']) )&&
                    <Tab 
                        label={<HaloTabLabel label='Roster Mgmt.' element={
                            <Chip label='Dev Beta' color="warning" size='small' sx={{ml:1}}/>
                        }/>}
                        value='Roster Mgmt.'
                        icon={<DashboardOutlinedIcon color='primary' sx={{height:20, width:20}}/>}
                        iconPosition="start"    
                        
                    />
            }
        </HaloTabs>
    )
}
