import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useGetQAReviewerListQuery } from "../../qualityAPISlice";
import DefaultNoRowsOverlay from "../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay";
import { MemoCell } from "../../../globalComponents/DataGrid/performance/MemoGridItems";
import { linkedRowProps } from "../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps";
import usePaginatedRowCount from '../../../globalComponents/DataGrid/hooks/commonUseEffects/usePaginatedRowCount'
import { useDispatch, useSelector } from "react-redux";
import { selectQAListFilters, setQAListFilters } from "../../../admin/adminSlice";
import CaseStatusChip from "../../../rosterManagement/utils/StatusChips/CaseStatusChip";
import { stringToSentenceCase } from "../../../../utils/stringUtils/sentenceCase";
import dayjs from "dayjs";
import LinkedPatientCell from "../../../globalComponents/DataGrid/subComponents/customCells/LinkedPatientCell";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import SingleFilterChip from "../../../globalComponents/Chips/DropDownChip/SingleFilterChip/SingleFilterChip";


const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width:88,
    },
    {
        field:'case_type',
        headerName: 'Type',
        width:100,
        sortable:false,
    },
    {
        field: 'patient_name',
        headerName: 'Patient',
        flex:1,
        sortable:false,
        renderCell: (params) => (
            <LinkedPatientCell
                patientName={params?.value}
                patientId={params?.row?.patient_id}
            />
        )
    },
    {
        field: 'clinician',
        headerName: 'Clinician',
        flex:1,
        sortable:false,
    },
    {
        field: 'review_state',
        headerName: 'QA status',
        width:150,
        sortable:false,
        renderCell: ({row}) => (
            <CaseStatusChip caseStatus={stringToSentenceCase(row?.review_state_string)} />
       )
    },
    {
        field: 'created_at',
        headerName: 'Date submitted',
        width:150,
        valueFormatter: (value) => {
            if(!value) return ''
            return dayjs(value).format('LL')
        }
    },
    {
        field: 'final_decision',
        headerName: 'Review decision',
        flex:2,
        sortable:false,
        valueGetter: (value, row) => row?.final_decision_string || '-'
    },
    {
        field: 'score_percentage',
        headerName: 'Score',
        width:88,
        valueFormatter: (value) => {
            if(value) return value+ '%'
            else return '-'
        }
    }
]

const sxProps = {
    ...linkedRowProps
}

const QAStatusOptions = [
    { label:'To do', value: '0,1'},
    { label:'Approved', value: '2' },
    { label:'Rejected', value: '3' },

]

export default function QAReviewPanel(){

    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useSelector(selectQAListFilters)
    const {data, isLoading, isFetching} = useGetQAReviewerListQuery(params)
    const rowCountState = usePaginatedRowCount(data?.count)

    const setParams = (state) => dispatch(setQAListFilters(state))
    const resetPaginaton = () => setParams({pagination: { page:0, pageSize:10 }})
    const handlePaginationChange = (pageModel) => setParams({pagination:pageModel}) 

    const handleFilterChange = (filterName) => (value) => {
        setParams({[filterName]: value })
        resetPaginaton()
    };

    const handleSortChange = (sortModel) => {
        const {field, sort} = sortModel[0] || {}
        
        let order = field

        if(sort === 'desc'){
            order = '-' + order
        }

        resetPaginaton()
        setParams({ordering:order})
    }


    const handleCellClick = useCallback(({id, field}) => {

        if (field.includes('__') || field === 'patient_name') return

        navigate('quality-assurance/review/' + id)

    }, [])




    return(
    <>
        <Box className='flex-row-between'>
            <SingleFilterChip
                chipLabel='Status'
                options={QAStatusOptions}
                filterState={params?.review_state}
                setFilterState={handleFilterChange('review_state')}
            />
        </Box>
        <Box sx={{...theme.standardBox}}>
            <DataGridPro 
                columns={columns}
                rows={data?.results || []}
                paginationMode="server"
                sortingMode="server"
                pagination
                autoHeight
                disableColumnMenu
                disableColumnResize
                rowCount={rowCountState}
                loading={isLoading || isFetching}
                pageSizeOptions={[5, 10, 15, 20, 50]}
                onSortModelChange={handleSortChange}
                paginationModel={params.pagination}
                onPaginationModelChange={handlePaginationChange}
                onCellClick={handleCellClick}
                slots={{
                    noRowsOverlay: DefaultNoRowsOverlay,
                    cell:MemoCell
                  }}
                slotProps={{
                    noRowsOverlay: { message: 'No pending qa reviews' },
                }}
                sx={sxProps}
            />
        </Box>
    </>
    )


}
