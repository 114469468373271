import { useEffect, useRef, useState, useCallback } from 'react';
import { useTheme } from "@emotion/react";
import { FilledInput, InputAdornment, IconButton, Box } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { debounce } from "lodash";

// Bloated component, refactor to match the GridAPISearchBar component
export default function ExpandingSearchBar({ initValue='', parentSetter }) {
    const theme = useTheme();
    const [typedQuery, setTypedQuery] = useState(initValue)
    const [isExpanded, setIsExpanded] = useState(Boolean(initValue));


    const inputRef = useRef(null)

    useEffect(() => {
        if(initValue){
            setTypedQuery(initValue)
            setIsExpanded(true)
        }
    }, [initValue])

    const handleBlur = () => {
        // Close the input if there's no search value
        if ( !typedQuery ) {
            setIsExpanded(false);
        }
    };

    const handleMouseEnter = () => {
        setIsExpanded(true)
    };

    const handleChange = (event) => setTypedQuery(event.target.value)

    const handleClear = () =>{
        setTypedQuery('')
        inputRef.current.focus()
    }

    useEffect(() => {
        if (isExpanded && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isExpanded]);

    const debouncedParentSetter = useCallback(
        debounce((params) => {
            parentSetter(params);
        }, 300),
        [parentSetter]
    );

    useEffect(() => {
        
        debouncedParentSetter(typedQuery)
        
    }, [typedQuery]);

    useEffect(() => {
        return () => {
            debouncedParentSetter.cancel();
        };
    }, [debouncedParentSetter]);
  
    return (
        <Box
        sx={{
            width: isExpanded ? 250 : 32,  // Toggle width based on state
            backgroundColor: isExpanded? theme.palette.primary.shades8 : 'transparent !important',
            transition: 'width 0.3s ease-in-out',
            p:'0px 0px 0px 12px',
            display:'flex',
            borderRadius: '18px',
            maxWidth:200
        }}>
            <FilledInput
                value={typedQuery}
                onChange={handleChange}
                onBlur={handleBlur}
                onMouseEnter={handleMouseEnter}
                startAdornment={
                    <InputAdornment position="start">
                            <SearchIcon sx={{height:24, width:24, p:0}} />
                    </InputAdornment>
                }
                endAdornment={
                    typedQuery && (
                        <InputAdornment position='end'>
                            <IconButton onClick={handleClear} sx={{p:'6px'}}>
                                <ClearIcon sx={{height:20, width:20, p:0}} />
                            </IconButton>
                        </InputAdornment>
                    )
                }
                inputRef={inputRef}
                placeholder="Search in table"
                disableUnderline
                sx={{
                    gap: 1,
                    padding: 0,
                    height: 32,
                    fontWeight: 500,
                    ...theme.typography.body2,
                    backgroundColor: 'transparent !important',
                    '& .MuiFilledInput-input': {
                        padding: 0,
                    },
                   
                }}
            />
        </Box>

    );
}
