import { Box, Skeleton } from "@mui/material";




export function HistoryEntryLoader(){



    return(
        <Box className='flex-row' gap={1} >
            <Skeleton variant="circular" height={36} width={36} />
            <Box className='flex-col' gap={1} width={'100%'}>
                <Skeleton height={20} width={'25%'} />
                <Skeleton height={12} width={'15%'}/>
                <Skeleton height={12} width={'20%'}/>
            </Box>  
        </Box>
    )
}