import { Box } from "@mui/material";
import { stringToTitleCase } from "../../../../utils/stringUtils/titleCase";
import BreadCrumbHeader from "../../../globalComponents/Headers/BreadCrumbHeader";
import CreatePatientButton from "../../../patients/admin/components/CreatePatientButton/CreatePatientButton";
import { selecteRosterMgmtPreset, selectRosterMgmtDates } from "../../adminSlice";
import RosterDateRangeButton from "../../../rosterManagement/admin/components/RosterAdminPanel/RosterDateRangeButton/RosterDateRangeButton";



export default function AdminHeader({user, tabValue}){

    

    return(
        <>
        <BreadCrumbHeader
            title={'Admin'}
            helperText={`Welcome to your admin dashboard, ${user.firstName ? stringToTitleCase(user.firstName) : ''}`}
            crumbs={[tabValue]}
            endComponent={
                <Box className='flex-row-center'>
                {
                tabValue === 'Patients' ? 
                    <CreatePatientButton />
                :
                tabValue === 'Roster Mgmt.'?
                    <RosterDateRangeButton
                        dateSelector={selectRosterMgmtDates} 
                        presetSelector={selecteRosterMgmtPreset}/>
                :
                    null
                }
                </Box>
            }
        />
        </>


    )



}
