
import TrialRecsAndComments from "../TrialRecommendations/TrialRecsAndComments/TrialRecsAndComments"
import CaseAccordion from "../../../../globalComponents/Accordions/CaseAccordion";
import SummaryContainer from "../../../../globalComponents/Containers/SummaryContainer";
import TrialMedications from "../TrialDataInput/Medications/TrialMedications";
import { selectTrialStaticMeds } from "../../../trialUserSlice";
import { memo, useCallback, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTutorial } from "../../../utils/TutorialContext";


function TrialRecommendations({ readOnlyView, allowEdits, caseInfo, isLoading, ...props }) {

    const { id, treating_provider, medication_review,
        treatment_recommendation,
        diagnostic_recommendation, comments } = props?.encounter || {}
    const meds = useSelector(selectTrialStaticMeds)

    const [width, setWidth] = useState(50)

    const dragHandler = useCallback(
        (e) => {
            const w =
                ((e.clientX -
                    e.currentTarget.parentElement.parentElement.getBoundingClientRect()
                        .left) /
                    e.currentTarget.parentElement.parentElement.getBoundingClientRect()
                        .width) *
                100;
            if (w > 0 && w !== width) {
                setWidth(w);
            }
        },
        [width]
    );

    const { refs } = useTutorial()

    const content = [
        <Box className='flex-row' key={'meds-and-recs'}>
            <Box width={width + '%'}>
                <TrialMedications
                    meds={meds}
                    key={'meds'}
                    readOnly={true}
                />
            </Box>
            <Box
                component={'div'}
                onDrag={dragHandler}
                draggable
                sx={{
                    width: 24,
                    cursor: 'col-resize',
                    '&:hover': {
                        'hr': {
                            borderRightWidth: 'thin'
                        }
                    },
                    '&:active': {
                        opacity: 0
                    }
                }}
            >
                <Divider orientation='vertical' sx={{ mr: '50%', borderRightWidth: 0 }} />
            </Box>
            <Box width={(100 - width) + '%'} ref={refs.current[3]}>
                {/* <div ref={refs.current[3]}> */}
                    <TrialRecsAndComments
                        id={id}
                        readOnly={readOnlyView}
                        treating_provider={treating_provider}
                        medication_review={medication_review}
                        treatment_recommendation={treatment_recommendation}
                        diagnostic_recommendation={diagnostic_recommendation}
                        comments={comments}
                        key={'recs'}
                    />
                {/* </div> */}
            </Box>
        </Box>,
    ]

    return readOnlyView ? (
        <SummaryContainer number={3} title={'Recommendations'} disableEdits={!allowEdits} caseInfo={caseInfo} isLoading={isLoading} baseURL={'/trial-users/encounter-review/'}>
            {content}
        </SummaryContainer>
    ) : (
            <CaseAccordion number={3} title={'Recommendations'} initialOpenState={true}>
                {content}
            </CaseAccordion>

    );

}


export default memo(TrialRecommendations)
