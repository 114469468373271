import { Box } from "@mui/material"
import DataInput from "./DataInput/DataInput";
import Analysis from "./Analysis/Analysis";
import Recommendations from "./Recommendations/Recommendations";






export default function PathologyDetailsContainer({ encounter, isLoading, allowEdits = false, caseInfo, qaObj }) {

    const { patient, lab_values, imaging_values, drug_interactions, resistance_genes, findingsmetrics
    } = encounter || {};
    const { medications, allergies, icd10_references } = patient || {};


    return(
        <>
            <DataInput
                meds={medications}
                allergies={allergies}
                diagnoses={icd10_references}
                readOnlyView={true}
                allowEdits={allowEdits}
                caseInfo={caseInfo}
                isLoading={isLoading}
            />
            <Analysis
                labValues={lab_values}
                resistanceGenes={resistance_genes}
                imagingValues={imaging_values}
                drugInteractions={drug_interactions}
                readOnlyView={true}
                allowEdits={allowEdits}
                caseInfo={caseInfo}
                qaObj={qaObj}
                isLoading={isLoading}
            />
            <Recommendations
                meds={medications}
                metrics={findingsmetrics}
                encounter={encounter}
                readOnlyView={true}
                allowEdits={allowEdits}
                caseInfo={caseInfo}
                qaObj={qaObj}
                isLoading={isLoading}
            />
        </>

    )

}
