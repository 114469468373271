import { Box } from "@mui/material";
import ExpandingSearchBar from "../../../../globalComponents/SearchBars/ExpandingSearchBar";
import SingleFilterChip from "../../../../globalComponents/Chips/DropDownChip/SingleFilterChip/SingleFilterChip";
import SitesFilterChip from "../../../../globalComponents/Chips/DropDownChip/SItesFilterChip/SitesFilterChip";






const caseTypeOptions = [
    { label: 'Pathology', value: 'Pathology Encounter' },
    { label: 'Infectious Disease', value: 'Infectious Disease Encounter' }
  ];
  

export default function HistoryFilters({ setParams, queryOptions }){



    const handleFilterChange = (filterName) => (value) => {
        setParams({[filterName]: value })
    };

    return(
        <>
        <Box className="flex-row" gap={1}>
            <SingleFilterChip
                chipLabel="Type"
                options={caseTypeOptions}
                filterState={queryOptions.type}
                setFilterState={handleFilterChange('type')}
                disableClear
            />
            <SitesFilterChip
                filterState={queryOptions.sites}
                setFilterState={handleFilterChange('sites')}
            />
            
        </Box>
        <ExpandingSearchBar
                initValue={queryOptions.searched}
                parentSetter={handleFilterChange('searched')}
            />
        </>
    )


}