import {  SmallHeaderBox, TypographySubtitle } from "../../../../globalComponents/SubHeader/components";
import { Box } from "@mui/material";
import { stringToTitleCase } from "../../../../../utils/stringUtils/titleCase";
import BackButton from "../../../../globalComponents/SubHeader/Backbutton";






export default function BasicPatientHeader({patientName}){


    return(
        <SmallHeaderBox>
            <Box className='flex-row-center' gap={1}>
            <BackButton/>
            <TypographySubtitle> {stringToTitleCase(patientName)} </TypographySubtitle>
            </Box>
        </SmallHeaderBox>
    )
}
