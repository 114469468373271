import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentTrialEncounter } from "../trialUserSlice";
import TrialPathologyFinalizeContainer from "../components/TrialPathologyReview/TrialPathologyFinalizeContainer"
import TrialInfecDisFinalizeContainer from "../components/TrialInfectiousDiseaseReview/TrialInfecDisFinalizeContainer";
import TrialSuccessMessage from "../components/TrialEncounter/TrialSuccessMessage"
import CaseFinalizationNav from "../../rosterManagement/components/CaseNavHeaders/CaseFinalizationNav";
import { useGetTrialEncounterQuery } from "../trialUserAPISlice";



export default function TrialEncounterFinalization() {
    let { encounterType, trialEncounterId } = useParams()

    const patient = useSelector(selectCurrentTrialEncounter)?.patient

    const [isSubmitted, setSubmitted] = useState(false)

    const { data, isLoading, isFetching } = useGetTrialEncounterQuery({ encounterType, trialEncounterId }, { refetchOnMountOrArgChange: true })

    return (
        <>
            <CaseFinalizationNav isSubmitted={isSubmitted} patientName={patient.name} isLoading={isLoading || isFetching} />

            <Box p={3}>
                {isSubmitted ?
                    <TrialSuccessMessage encounterType={encounterType} patient={patient} />
                    :
                    encounterType === 'Infectious Disease' ?

                        <TrialInfecDisFinalizeContainer
                            isLoading={isLoading || isFetching}
                            setSubmitted={setSubmitted}
                            treatingProvider={data?.trial_encounter?.treating_provider} />
                        :
                        <TrialPathologyFinalizeContainer
                            isLoading={isLoading || isFetching}
                            setSubmitted={setSubmitted}
                            treatingProvider={data?.trial_encounter?.treating_provider}
                        />
                }
            </Box>
        </>
    )
}
