import { apiSlice } from "../../app/api/apiSlice";


export const rosterAdminApiSlice = apiSlice
.enhanceEndpoints({addTagTypes:['Roster Admin | All', 'Roster Admin | Stats', 'Clinicians Grid']})
.injectEndpoints({
    endpoints: builder => ({
        getCasePerformanceStats: builder.query({
            query: ({dates, userId=false}) => {
                let url = `roster/case-stats/performance/${ dates?.join(',') }`
                if(userId) url += `/?user_id=${userId}`

                return url
            }
        }),
        getCaseAsgmtStats: builder.query({
            query: (dates) => `roster/case-stats/assignment/${ dates?.join(',') }`
        }),
        getCaseStatsBarCharts: builder.query({
            query: ({dates, statType, userId=false}) => {
                let url = `roster/case-stats/bar-chart/${ dates?.join(',') }/${statType}/`
                if(userId) url += `?user_id=${userId}`

                return url
            }
        }),
        getCaseDoughnutCharts: builder.query({
            query: ({dates, statType}) => `roster/case-stats/doughnut-chart/${ dates?.join(',') }/${statType}`
        }),
        getUsersCaseStats: builder.query({
            query: (dates) => `roster/case-stats/users/${ dates?.join(',') }`,
            providesTags: ['Roster Admin | All']

        }),
        getCaseObjects: builder.query({
            query: ({pagination, ordering, search, resolution_status, case_type, sites, user}) => {
                let url = `roster/cases/today/?page=${pagination.page + 1}&ordering=${ordering}&size=${pagination.pageSize}&search=${search}&resolution_status=${resolution_status}&case_type=${case_type}&user=${user?.id}`
                
                if (sites?.length) url += `&site=${sites?.map((site) => site?.id).join(',')}`

                return url
            },
            providesTags: ['Roster Admin | All']
        }),
        getSiteAndSettings: builder.query({
            query: (siteId) => `roster/distribution/site/${siteId}`
        }),
        getClinicianAndSettings: builder.query({
            query: (clinicianId) => `roster/distribution/clinician/${clinicianId}`,
        }),
        getClinicianCaseAsgmtHistory: builder.query({
            query: ({dates, clinicianId}) => `roster/case-asmgts/history/${dates?.join(',')}/${clinicianId}/?size=40`
        }) ,
        manageCases: builder.mutation({
            query:({method, body, type}) =>({
                url: 'roster/cases/asgmt/' + type,
                method,
                body
            }),
            invalidatesTags:['Roster Admin | All']
        }),
        unassignCases: builder.mutation({
            query:(payload) => ({
                url: 'roster/cases/unasgmt',
                method:'PUT',
                body: payload
            }),
            invalidatesTags:['Roster Admin | All']
        }),
        removeCasesFromBatch: builder.mutation({
            query: (payload) => ({
                url:'roster/cases/batch-remove',
                method: 'PUT',
                body: payload
            }),
            invalidatesTags:['Roster Admin | All']
        })
    })
})


export const { 
    useGetCasePerformanceStatsQuery, useGetCaseAsgmtStatsQuery, useGetCaseStatsBarChartsQuery, useGetCaseDoughnutChartsQuery, useGetUsersCaseStatsQuery,
    useGetCaseObjectsQuery, useGetSiteAndSettingsQuery, useGetClinicianAndSettingsQuery, useGetClinicianCaseAsgmtHistoryQuery, useManageCasesMutation, useUnassignCasesMutation, useRemoveCasesFromBatchMutation
} = rosterAdminApiSlice
