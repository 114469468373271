import { Box } from "@mui/material";
import SingleFilterChip from "../../../../../../../globalComponents/Chips/DropDownChip/SingleFilterChip/SingleFilterChip";
import { useGetCaseTypesQuery, useGetRosterUsersQuery } from "../../../../../../rosterAPISlice";
import SitesFilterChip from "../../../../../../../globalComponents/Chips/DropDownChip/SItesFilterChip/SitesFilterChip"
import SingleAsyncObjFilterChip from "../../../../../../../globalComponents/Chips/DropDownChip/AsyncObjFilterChip/SingleAsyncObjFilterChip";



const statusOptions= [
    { label: 'Pending', value: 'pending' },
    { label:'Pending results', value:'pending_results'},
    { label: 'In Progress', value: 'in_progress' },
    { label: 'Assigned', value: 'assigned' },
    { label: 'Completed', value: 'completed' },
    { label: 'Delayed', value: 'delayed' },
    { label: 'No Action Needed', value: 'no_action' },
    { label: 'Failed', value: 'failed' },
    { label: 'Did Not Finish', value: 'dnf' },
    // might use these if looking at old objects
        // { label: 'Resolved', value: 'resolved' }
]






export function Filters({
    handleFilterChange,
    queryParams
}){

    const { data:caseTypes, isLoading: loadingTypes, isFetching: fecthingTypes } = useGetCaseTypesQuery()

    return(
        <Box className='flex-row' sx={{minHeight: 52, p:'8px 16px', alignItems:'center', gap:1, flexWrap:'wrap'}} >
            <SingleFilterChip
                chipLabel='Type'
                options={caseTypes?.map((type) => ({label:type.name, value:type.id}))}
                filterState={queryParams?.case_type}
                setFilterState={handleFilterChange('case_type')}/>
            <SitesFilterChip
                filterState={queryParams?.sites}
                setFilterState={handleFilterChange('sites')}/>
            <SingleFilterChip 
                chipLabel='Status'
                options={statusOptions}
                filterState={queryParams?.resolution_status}
                setFilterState={handleFilterChange('resolution_status')}/>
            <SingleAsyncObjFilterChip 
                chipLabel={'Clinician'}
                query={useGetRosterUsersQuery}
                filterState={queryParams?.user}
                setFilterState={handleFilterChange('user')}
                optionKey='name'
            />
        </Box> 
    )
}