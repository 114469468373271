import { useParams } from "react-router-dom"
import { useGetDashboardDataQuery } from "../dashboardApiSlice"
import { Box } from "@mui/material"
import Header from "../components/Header/Header"
import ToDoList from "../components/ToDoList/ToDoList"
import StatsAndPreferences from "../components/StatsAndPreferences/StatsAndPreferences"
import Benchmarking from "../components/Benchmarking/Benchmarking"
import {dateRangeToString} from "../../../utils/dateUtils/dateRangeToString"
import dayjs from "dayjs"


const weekStart = dayjs().startOf('week')
const weekEnd = weekStart.add(6, 'day');
const weekRangeStr = dateRangeToString(weekStart, weekEnd)



export default function Dashboard(){
    let {data, isLoading} = useGetDashboardDataQuery()
    const {assignments, current_week_caseload, stats, billing_points, points_goal} = data || {}
    

    if(isLoading){
        <h1>loading...</h1>
    }

    
    return(
        <>
            <Box className='flex-col' p={3} gap={2} >
                <Header/>
                <Box className='flex-row' gap={3} >
                    <Box className='flex-col' gap={3} flexGrow={1} flex={1} minWidth={0}>
                        <ToDoList assignments={assignments} isLoading={isLoading}/>
                        <Benchmarking/>
                    </Box>
                    <Box className='flex-col' gap={2} minWidth={272} maxWidth={272}>
                        <StatsAndPreferences 
                            currentWeekCaseLoad={current_week_caseload}
                            stats={stats}
                            billingPoints={billing_points}
                            pointsGoalObj={points_goal}
                            weekRangeStr={weekRangeStr}/>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
