import { apiSlice } from '../../app/api/apiSlice'

export const billingApiSlice = apiSlice
    .enhanceEndpoints({ addTagTypes: ['Billing Stats'] })
    .injectEndpoints({
        endpoints: builder => ({
            getWeeklyBillingSummary: builder.query({
                query: () => '/billing/weekly-summary',
            }),
            getBillingStatsSummary: builder.query({
                query: ({ start_date, end_date }) => `billing/billing-points-summary/?start_date=${start_date}&end_date=${end_date}`,
                provideTags:['Billing Stats']
            })
        })
    })

export const {
    useGetWeeklyBillingSummaryQuery, useLazyGetBillingStatsSummaryQuery
} = billingApiSlice 
