import { useParams } from "react-router-dom"
import { useGetQAReviewQuery } from "../qualityAPISlice"
import QAReviewHeader from "../components/QAReview/QAReviewHeader/QAReviewHeader"
import { Box } from "@mui/material"
import QAReviewGrading from "../components/QAReview/QAReviewGrading/QAReviewGrading"
import PathologyDetailsContainer from "../../pathology/components/PathologyReview/PathologyDetailsContainer"
import { OverlayScrollbarsComponent } from "overlayscrollbars-react"
import HiddenScrollableBox from "../../globalComponents/Boxes/HiddenScrollableBox"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setCurrentScores, updateFieldScore } from "../qualitySlice"
import { setStaticMeds } from "../../pathology/pathologySlice"



export default function QAReview(){


    let { qualityId } = useParams()
    const dispatch = useDispatch()
    const { data, isLoading, isFetching } = useGetQAReviewQuery(qualityId)
    const { encounter, qa_data, tracked_duration} = data || {}




    useEffect(() =>{
        if(qa_data?.id){
            let {max_possible_score, score_only_fields} = qa_data || {}
            dispatch(setCurrentScores({
                max_possible_score,
                fields: score_only_fields
            }))
            dispatch(updateFieldScore({})) //prevents reset of current score values
            dispatch(setStaticMeds(
                encounter?.patient?.medications
            ))
        }

    },[qa_data?.max_possible_score, encounter])

    return(
        <>
        <QAReviewHeader
            loading={isLoading || isFetching}
            encounter={encounter}
            qaObj={qa_data}
            patient={encounter?.patient}
            trackedDuration={tracked_duration}
        />
        <Box className='flex-row' pl={3} height={'calc(100vh - 196px)'}>
            
            <HiddenScrollableBox sx={{flex:1}}>
                <QAReviewGrading 
                    qaObj={qa_data}
                    loading={isLoading || isFetching}
                    readOnly={qa_data?.review_state === 2 || qa_data?.review_state === 3}
                    patient={encounter?.patient}
                    currentClinician={encounter?.clinician_name}
                    />
            </HiddenScrollableBox>
            <OverlayScrollbarsComponent style={{flex:2}}>
                <Box className='flex-col' sx={{ m:3, gap:3}}>
                    <PathologyDetailsContainer  
                        isLoading={isLoading || isFetching}
                        encounter={encounter} 
                        />
                </Box>
            </OverlayScrollbarsComponent>
            
        </Box>

        </>
    )
}
