import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";




export const QABody = ({ data, theme }) => {

    const textSx = { ...theme.typography.caption, color: theme.palette.error.shades160 }



    return(
        <Box className='flex-col' gap={0.5}>
            <Typography sx={{ ...theme.typography.caption, color: theme.palette.error.main }}>
                Rejected on {dayjs(data.qa_feedback.created_at).format("MMM DD, YYYY h:mm A")}
            </Typography>
            <Box className='flex-col' sx={{ p: 1, borderRadius: 1, background: theme.palette.error.shades4 }}>
                <Typography sx={textSx}>
                    Score: {data.qa_feedback?.score_percentage}%
                </Typography>
                <Typography sx={textSx}>
                    Reviewer: {data.qa_feedback?.qa_reviewer_name}
                </Typography>
                <Typography sx={textSx}>
                    General feedback: {data.qa_feedback?.final_feedback}
                </Typography>

            </Box>
        </Box>
    )
}