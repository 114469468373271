import { useTheme } from '@emotion/react'
import { Box, Table, TableBody, TableContainer, TableRow, TableCell, TableHead, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../auth/authSlice'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { useNavigate } from 'react-router-dom'
import openDocInNewTab from '../../../../../utils/apiUtils/openDocInNewTab'


// may abstract into global component
const TableSkeleton = ({ children, tableName }) => {

    const theme = useTheme()

    return (
        <Box sx={{ width: 688, m: '0 auto', ...theme.standardBox }}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>{tableName}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {children}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}


const ProfileInfoRow = ({ infoType, value, navPath, user }) => {

    const navigate = useNavigate()
    const handleNav = () => {
        if (navPath === 'BAA') {
            openDocInNewTab(`trial-users/show-baa`)
        }

        else if (navPath) {
            navigate(`/profile/${navPath}`)
        }


    }

    return (
        <TableRow
            onClick={handleNav}
            hover={Boolean(navPath)}
            sx={{
                ':hover': { cursor: Boolean(navPath) ? 'pointer' : '' },
                '&:last-child td, &:last-child th': { border: 0 },
                '& .MuiTableCell-root': {
                    height: 64,
                    padding: '8px 16px',
                }
            }}>
            <TableCell sx={{ width: 160 }}>{infoType}</TableCell>
            <TableCell>{value}</TableCell>
            <TableCell align='right'>
                {infoType === 'Email' ? null :
                    infoType === 'Avatar' ? <></> :
                        <KeyboardArrowRight />
                }
            </TableCell>
        </TableRow>
    )
}

function MyProfilePanel() {

    const user = useSelector(selectCurrentUser)

    return (
        <>
            <TableSkeleton tableName='Basic info'>
                <ProfileInfoRow infoType='Name' value={user?.name} navPath='edit-name' />
                <ProfileInfoRow infoType='Preferred title' value={user?.preferredTitle} navPath='edit-preferred-title' />
                {/* <ProfileInfoRow infoType='Avatar' value={''}/> */}
                {/* Will give user the ability to select a profile picture at a later time */}
            </TableSkeleton>

            <TableSkeleton tableName='Contact info'>
                <ProfileInfoRow infoType='Email' value={user.email} />
                <ProfileInfoRow infoType='Phone' value={user?.phone ? user?.phone : 'Add a phone number'} navPath='edit-phone-number' />
            </TableSkeleton>

            {/* Will come up with a more flexible/scalable way to check if any documents are present as new documents are added to the profile page */}
            {user.baa?.consented_to_esign && <TableSkeleton tableName='Documents'>
                <ProfileInfoRow infoType='BAA' value={'Signed business associate agreement'} navPath={'BAA'} user={user} />
            </TableSkeleton>}

        </>
    )
}

export default MyProfilePanel
