import DataInput from "./DataInput/DataInput"
import Samples from "./Samples/Samples";
import Recommendations from "./Recommendations/Recommendations";



export default function InfectiousDiseaseDetailsContainer({isLoading, encounter, allowEdits=false, caseInfo}){


    const { patient, general, samples, medications, d2d_interactions } = encounter || {};
  

    return(
        <>
            <DataInput 
                allergies={patient?.allergies}
                client={general}
                diagnoses={patient?.icd10_references}
                readOnlyView={true}
                allowEdits={allowEdits}
                caseInfo={caseInfo}
                isLoading={isLoading}
                />
            <Samples 
                initSamples={samples}
                readOnlyView={true}
                allowEdits={allowEdits}
                caseInfo={caseInfo}
                isLoading={isLoading}
                />
            <Recommendations
                medInstructions={medications}
                general={general}
                drugInteractions={d2d_interactions}
                readOnlyView={true}
                allowEdits={allowEdits}
                caseInfo={caseInfo}
                isLoading={isLoading}
                />
        </>
    )


}