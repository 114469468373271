import { memo, useState } from "react";
import HaloModal from "../../../../globalComponents/Modals/HaloModal";
import { useGetPatientProfileQuery } from "../../../../patients/patientAPISlice";
import { Alert, AlertTitle, Box, Skeleton, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { stringToTitleCase } from "../../../../../utils/stringUtils/titleCase";
import DetailItem from "../../../../globalComponents/TextGroups/DetailItem";
import dayjs from "dayjs";
import { calculateAge } from "../../../../../utils/dateUtils/calculateAge";
import AsgmtRadio from "./AsgmtRadio";
import { ActionButton } from "../../../../globalComponents/Buttons/ActionButton/ActionButton";
import GroupContainer from '../../../../globalComponents/Containers/GroupContainer'
import { useForm } from "react-hook-form";
import { HaloAsyncObjAutocomplete } from "../../../../globalComponents/ReactHookFormFields/AutocompleteFields/HaloAsyncObjAutocomplete";
import { useAssignToBatchOrClinicianMutation, useGetCaseTypesQuery, useGetRosterUsersQuery } from "../../../rosterAPISlice";
import { HaloDatePicker } from "../../../../globalComponents/ReactHookFormFields/DateFields/HaloDatePicker";
import { show400Error } from "../../../../../app/errors/genericErrors";
import { enqueueSnackbar } from "notistack";
import HaloSkeleton from "../../../../globalComponents/Skeletons/HaloSkeleton";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../auth/authSlice";




 function AssignmentModal({
    open, 
    handleClose, 
    patientId=null, //provide if need to fetch patient data 
    patientObj, //provide if already have patient data
    resetParentCallback = () => null, //handle any state in the parent component like clearing a form
    initAsgmtType='Manual assignment'
}){


    const theme = useTheme()
    const {id, name} = useSelector(selectCurrentUser)
    const [step, setStep] = useState(0)
    const [asgmtType, setAsgmtType] = useState(initAsgmtType)

    //if no patientId provided, the  component will use patient Obj
    const {data, isFetching, isLoading} = useGetPatientProfileQuery({patientId, extensive:false}, {skip:!Boolean(patientId)})
    const [triggerAsgmt, {isLoading:isAssigning}] = useAssignToBatchOrClinicianMutation()
    const patient = data?.patient || patientObj || {}
    const { date_of_birth, zip, next_appt_date, last_appointment_date, last_path_review, site, active_case, existing_batches} =  patient || {}
    const { handleSubmit, control, reset } = useForm({ 
            defaultValues:{
                batch_date: null,
                case_type: '',
                clinician: { id, name: name + ' (Me)' }
            } 
        });

    const handleNext = (e) => {
        setStep(prev => prev +1)
    }

    const handleBack = (e) => {
        setStep(prev => prev -1)
    }

    const handleAssignment = (values) => {
        handleClose()
        triggerAsgmt({
            target: asgmtType === 'Manual assignment' ? 'clinician' : 'batch',
            body:{
                patient_id: patient?.id,
                ...values
            }
        })
        .unwrap()
        .then(res=>{
            reset()
            enqueueSnackbar(asgmtType === 'Manual assignment' ? 'Successfully assigned' : 'Successfully batched', {variant:'success'})
            setAsgmtType('Manual assignment')
            setStep(0)
            resetParentCallback()
        })
        .catch(err => show400Error(err))
    }   
    

    return(
        <HaloModal 
            open={open} 
            handleClose={handleClose}
            modalMaxWidth={850}
            title={'Assign patient case'}>
            <Box className='flex-col' gap={2} p={2} component={'form'} onSubmit={handleSubmit(handleAssignment)} noValidate>
                <Box
                    className='flex-col'
                    sx={{
                        p:2,
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius:2,
                        gap:1
                    }}>
                     <HaloSkeleton loading={isLoading || isFetching}>
                        <Typography variant="subtitle1">{stringToTitleCase(patient?.name)}</Typography>
                     </HaloSkeleton>
                    <Box className='flex-col' gap={1}>
                        <Box className='flex-row' gap={1}>
                            <DetailItem 
                                label="Date of Birth" 
                                value={date_of_birth? dayjs(date_of_birth).format("MMM D, YYYY") :null} 
                                loading={isLoading || isFetching}
                                />
                            <DetailItem 
                                label="Age" 
                                value={calculateAge(date_of_birth)}
                                loading={isLoading || isFetching}  />
                            <DetailItem
                                label="Next appt." 
                                value={next_appt_date? dayjs(next_appt_date).format('MMM D, YYYY') : null} 
                                loading={isLoading || isFetching}
                                />
                            <DetailItem 
                                label="Last appt." 
                                value={last_appointment_date? dayjs(last_appointment_date).format('MMM D, YYYY') :null} 
                                loading={isLoading || isFetching}
                                />
                        </Box>
                        <Box className='flex-row' gap={1}>
                            <DetailItem 
                                label={'Site'}
                                value={site?.site_name}
                                loading={isLoading || isFetching}
                                />
                            <DetailItem
                                label="Last path review" 
                                value={last_path_review?.date ? dayjs(last_path_review?.date).format("MMM D, YYYY") :null} 
                                link={`/caseload/encounter-details/${last_path_review?.type}/${last_path_review?.encounter_id}`}
                                loading={isLoading || isFetching}
                                />
                            <DetailItem 
                                label='Zip' 
                                value={zip}  
                                flexBasis={'45%'}
                                loading={isLoading || isFetching}
                                />
                        </Box>
                    </Box>
                </Box>
                {
                            existing_batches?.length > 0 &&
                            <Alert severity="warning" sx={{borderRadius:2}}>
                                <AlertTitle>Patient scheduled for batch distribution</AlertTitle>
                                {
                                    existing_batches?.map(batch => {
                                        return (
                                            <span key={batch?.batch_date}>
                                                {`This patient is currently in the ${dayjs(batch.batch_date).format('MMM DD YYYY')} Batch`}
                                                <br />
                                            </span>
                                        );
                                    })
                                }
                            </Alert>
                }
                {
                    active_case?.clinician ? 
                    <Alert severity="warning" sx={{borderRadius:2}}>
                        <AlertTitle>Case is in progress</AlertTitle>
                        This {active_case?.case_type} Case is currently assigned to {active_case?.clinician} 
                    </Alert>
                    :
                    <>
                        
                        <Box className='flex-col' gap={1}>
                            {
                                step === 0 && 
                                <>
                                <HaloSkeleton loading={isLoading || isFetching}>
                                    <Typography variant="body2">Select assignment method</Typography>
                                </HaloSkeleton>
                                    <Box className='flex-row' gap={2} height={172}>
                                    {
                                        isLoading || isFetching?
                                            <>
                                            <Skeleton height={170} width={'100%'}/>
                                            <Skeleton height={170} width={'100%'}/>
                                            </>
                                            :
                                            <>
                                            <AsgmtRadio type="manual" onClick={() => setAsgmtType('Manual assignment')} isActive={asgmtType === 'Manual assignment'} theme={theme}/>
                                            <AsgmtRadio type="batch" onClick={() => setAsgmtType('Batch distribution')} isActive={asgmtType === 'Batch distribution'} theme={theme}/>
                                            </>
                                    }
                                    </Box>
                                </>
                            }
                            {
                                step === 1 &&
                                <GroupContainer title={asgmtType}>
                                    <Box className='flex-col' gap={2}>
                                        {
                                            asgmtType === 'Manual assignment'?
                                                <>
                                                <HaloAsyncObjAutocomplete 
                                                    label='Clinician'
                                                    name='clinician'
                                                    query={useGetRosterUsersQuery}
                                                    queryParams={{
                                                        allowedSite:patient?.site?.id
                                                    }}
                                                    freeSolo
                                                    selectOnFocus
                                                    optionKey='name'
                                                    required
                                                    control={control}
                                                    componentsProps={{
                                                        popper: {
                                                          modifiers: [
                                                            {
                                                              name: 'flip',
                                                              enabled: false,
                                                            },
                                                            
                                                          ]
                                                        }
                                                      }} 
                                                   
                                                />
                                                <HaloAsyncObjAutocomplete
                                                    label='Case type'
                                                    name='case_type'
                                                    query={useGetCaseTypesQuery}
                                                    queryParams={{site:site?.id}}
                                                    optionKey={'name'}
                                                    required
                                                    control={control}
                                                />
                                                </>
                                                :
                                                <HaloDatePicker
                                                    label='Select batch date'
                                                    name='batch_date'
                                                    required
                                                    minDate={dayjs()}
                                                    maxDate={dayjs().add(2, 'month')}
                                                    disablePast
                                                    control={control}
                                                />
                                        }
                                    </Box>
                                </GroupContainer>
                            }
                        </Box>
                        
                        <Box className='flex-row' justifyContent={'flex-end'}  pt={1}>
                            {
                                step !== 0 &&
                                <ActionButton variant='outlined' onClick={handleBack}>
                                    Back
                                </ActionButton>
                            }
                            <Box className='flex-row' flex={1} justifyContent={'flex-end'} >
                                {
                                    step === 0 &&
                                    <HaloSkeleton loading={isLoading || isFetching}>
                                        <ActionButton variant='contained' onClick={handleNext}>
                                            Next
                                        </ActionButton>
                                    </HaloSkeleton>
                                }
                                {
                                    step === 1 &&
                                    <ActionButton variant='contained' type='submit' loading={isAssigning}>
                                        {asgmtType === 'Manual assignment' ? 'Manual assign' : 'Batch distribute' }
                                    </ActionButton>
                                }
                            </Box>
                        </Box>
                    </>
                }
            </Box>
        </HaloModal>
    )


}


export default memo(AssignmentModal)
