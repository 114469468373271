import { Box } from '@mui/material'
import SummaryContainer from './SummaryContainer/SummaryContainer'
import PatientTabs from '../PatientTabDetails/PatientTabs'
import IPCConsult from '../IPCConsult/IPCConsult'
import { useState } from 'react'
import { useManagePatientIPCMutation } from '../../../patientAPISlice'
import { useParams } from 'react-router-dom'
import PatientHistory from './PatientHistory/PatientHistory'
import { showGenericValidationError } from '../../../../../app/errors/genericErrors'
import { enqueueSnackbar } from 'notistack'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'


const scrollingSx = { 
    flexGrow: 1, 
    overflowY: 'auto', 
    pr:3
}

const defaultValues = {
    encounter_date: dayjs(),
    duration: '',
    provider: '',
    communication_method:'',
    details:'',
}

export default function PatientSummary({history, assignments, ipcHistory }){

    let {patientId} = useParams()
    
    const [isBottom, setIsBottom] = useState(false)
    const [createIPC] = useManagePatientIPCMutation() 
    const {handleSubmit, control, reset} = useForm({ defaultValues })
    const [tab, setValue] = useState('Summary')


    const handleTabChange = (event, newValue) => setValue(newValue)
    
    const submitIPC = (values) => {
            reset(defaultValues)
            createIPC({
                patientId,
                method:'POST',
                body:{
                    ...values
                },
                patch:{
                    ...values,
                    //fomat to work with optimistic update
                    encounter_date : values.encounter_date.format('YYYY-MM-DD')
                }
            })
            .unwrap()
            .then(res =>{
                enqueueSnackbar('Succesfully saved IPC', {variant:'success'})
            })
            .catch(err => {
                reset(values)
                showGenericValidationError(err)
            } )
    }
    
    const handleScroll = (e) => {

        const bottom = Math.abs(e.target.scrollHeight - Math.floor(e.target.scrollTop) - e.target.clientHeight) <= 3;
        if(bottom ){
            setIsBottom(true)
        }
    }

    return(
        <>
            <Box sx={scrollingSx}
                onScroll={handleScroll}>
                <Box className='flex-col' gap={3} ml={3} mt={3} mb={3}>
                    <PatientTabs tab={tab} handleTabChange={handleTabChange}/>
                    {tab === 'Summary' ?
                        <>
                        <SummaryContainer assignments={assignments}/>
                        <PatientHistory 
                            history={history} 
                            patientId={patientId}
                            isBottom={isBottom}
                            setIsBottom={setIsBottom}
                            />
                        </>
                        :
                        <IPCConsult ipcHistory={ipcHistory} control={control} handleSubmit={handleSubmit} submitIPC={submitIPC}/>
                    }
                </Box>
            </Box>
        
        </>
    )
}