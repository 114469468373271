import { Box, Divider, Tab } from "@mui/material"
import HaloTabs from "../../../../../../globalComponents/Tabs/HaloTabs"
import HaloTabLabel from "../../../../../../globalComponents/Tabs/TabLabels/HaloTabLabel"
import { useState } from "react"
import { General } from "./Panels/General"
import { WeeklySchedule } from "./Panels/WeeklySchedule"



export function Info({info, schedule}){

    const [ tabValue, setTabValue ] = useState('General') 

    const handleTabChange = (e, value) => setTabValue(value) 

    return(
        <Box sx={(theme) => ({...theme.standardBox, height:340, flex:1, minWidth:'fit-content'})}>
            <HaloTabs value={tabValue} onChange={handleTabChange}>
                <Tab 
                    label={<HaloTabLabel label='General'/>}
                    value='General' />
                <Tab 
                    label={<HaloTabLabel label='Notes'/>}
                    value='Notes' />
                <Tab 
                    label={<HaloTabLabel label='Schedule'/>}
                    value='Schedule' />
            </HaloTabs>
            <Divider/>
            <Box className='flex-row' p={2} gap={3}>
                {
                    tabValue === 'General' ?
                        <General info={info}/>
                    :
                    tabValue === 'Notes' ?
                        null
                    : 
                    tabValue === 'Schedule'?
                        <WeeklySchedule schedule={schedule}/>
                    :
                        null
                }
            </Box>
        </Box>
    )
}