


import { Autocomplete, TextField, CircularProgress, } from "@mui/material"
import { useState } from "react";
import { useController } from "react-hook-form";




//it gets frustrating needing to customize object options
//will be derived form a Base HaloAutocomplete in the future, for now this will do 
export function HaloAsyncObjAutocomplete({ 
  control, 
  name, 
  label, 
  required, 
  query, //need to be an RTK query e.g. useGetSites
  queryParams=undefined,
  optionKey, // use to compar object values
  ...otherProps
}) {
    const {
      field,
      formState: {  errors },
    } = useController({
      name,
      control,
      rules: { required: required? 'This field is required' : false },
    })
    const [isOpen, setOpen] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const { data:options, isLoading } = query(queryParams, { skip: isOpen });

    return(
        <Autocomplete
            {...otherProps}
            fullWidth
            openOnFocus
            autoComplete
            value={field.value || null}
            onChange={(event, newValue) => {
              field.onChange(newValue)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={options || []}
            getOptionLabel={(option) => option[optionKey] || ''}
            isOptionEqualToValue={(option, val) => option[optionKey] === val[optionKey] || option[optionKey] === null}
            renderOption={(props, option) => ( //allows for different keys for obejcts with similiar option labels
              <li {...props} key={option.id}>
                {option[optionKey]}
              </li>
            )}
            renderInput={(params) => (
              <TextField 
                {...params} 
                required={required} 
                label={label} 
                size="small" 
                error={Boolean(errors[name])}
                helperText={errors[name]?.message}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                      <>
                          {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                      </>
                  ),
              }}/>
            )}
        />
      )
  
  }
  
  