import { SmallHeaderBox } from "../../../../globalComponents/SubHeader/components";
import { Box, Skeleton } from "@mui/material";






export default function BasicPatientHeaderLoader(){


    return(
        <SmallHeaderBox>
            <Box className='flex-row-center' gap={1}>
                <Skeleton animation="wave" width={100}/>
            </Box>
        </SmallHeaderBox>
    )
}