import { Typography } from "@mui/material";
import dayjs from "dayjs";




export const EncounterBody = ({ type, data, theme }) => (
    <>
        <Typography sx={{ ...theme.typography.caption, color: theme.palette.text.disabled }}>
            Date submitted: {dayjs(type === 'Infectious Disease Encounter' ? data.submitted_at : data.date_submitted).format('MMMM D, YYYY h:mm A')}
        </Typography>
        <Typography sx={{ ...theme.typography.caption, color: theme.palette.text.disabled }}>
            Clinician: {data.clinician}
        </Typography>
    </>
);
