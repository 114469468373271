import { useTheme } from "@emotion/react";
import { Box, Divider, Typography } from "@mui/material";












export default function GroupContainer({ title, children}){

    const theme = useTheme()

    return(
        <Box sx={{...theme.standardBox,}}>
             <Box sx={{
                p:'8px 16px',
                justifyContent:'space-between',
                height:44.8,
            }}>
                <Typography variant="body1" sx={{ color: theme.palette.text.primary}}>{title}</Typography>
            </Box>
            <Divider/>
            <Box p={2}>
                {children}
            </Box>
        </Box>
    )

}