import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { getEntryIcon } from './EntryIcon/getEntryIcon';
import { QABody } from './EntryBody/QABody';
import { EncounterBody } from './EntryBody/EncounterBody';
import { LabBody } from './EntryBody/LabBody';
import { useNavigate } from 'react-router-dom';







export default function HistoryEntry({ entry }) {
    const theme = useTheme();
    const navigate = useNavigate()
    const { type, data } = entry;
    const timeLineDot = getEntryIcon(type, theme);
    const isEncounter = type === 'Infectious Disease Encounter' || type === 'Pathology Encounter';
    const handleNav = () => {

        if(isEncounter){
            navigate(`/caseload/encounter-details/${type}/${data?.id}`)
        }else{
            const newTab = window.open();
            if (newTab) {newTab.location.href = `${process.env.REACT_APP_API_URL}roster/download/doc/${type}/${data?.id}/pdf`}
        }
    }


    return (
        <TimelineItem 
            onClick={handleNav}
            sx={{
                ':hover':{
                    background:theme.palette.action.hover,
                    cursor:'pointer'
                },
                pl:2,
                pr:2
            }}>
            <TimelineSeparator>
                {timeLineDot}
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <Box className='flex-row' gap={1}>
                    <Typography sx={{ ...theme.typography.body2 }}>
                        {type}
                    </Typography>
                </Box>
                <Box className='flex-col'>
                    {isEncounter ? (
                        data.qa_rejected ? (
                            <QABody data={data} theme={theme} />
                        ) : (
                            <EncounterBody type={type} data={data} theme={theme} />
                        )
                    ) : (
                        <LabBody data={data} theme={theme} />
                    )}
                </Box>
            </TimelineContent>
        </TimelineItem>
    );
}
