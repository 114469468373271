import { Box, Typography } from "@mui/material";
import HaloDropZone from "../../../../globalComponents/FileUpload/HaloDropZone";









export function Upload(){




    return(
        <>
            <Typography variant="h6">Upload CSV file</Typography>
            <HaloDropZone 
                fileTypes={['csv']}
                dropZoneSx={{
                    height:280,
                }}/>
        </>
    )
}