import { useState } from "react";
import HaloModal from "../../../globalComponents/Modals/HaloModal";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { Upload } from "./Steps/Upload";
import { ActionButton } from "../../../globalComponents/Buttons/ActionButton/ActionButton";



const steps =[ 
    'Upload',
    'Map',
    'Details'
]



export default function PatientIntakeModal({open, setOpen}){

    const [step , setStep ] = useState(0) 

    const handleNext = () => setStep((prev) => prev + 1 )
    const handleBack = () => setStep((prev) => prev - 1)


    return(
        <HaloModal
            open={open}
            handleClose={() => setOpen(false)}
            title='Upload data'
            modalMaxWidth={1200}
        >
            <Box p={2}>
                <Stepper activeStep={step} alternativeLabel>
                    {
                        steps?.map((step) => (
                            <Step key={step}>
                                <StepLabel >{step}</StepLabel>
                            </Step>
                        ))
                    }
                </Stepper>
            </Box>
            <Box className='flex-col-center' >
                {
                    step === 0 ?
                        <Upload />
                    :
                    step === 1 ?
                        'Step 2 stuff...'
                    :
                    null
                }
            </Box>
            <Box className='flex-row-between' alignItems='center' p={2}>
                <ActionButton 
                    variant='outlined' 
                    sx={{width:120}}
                    onClick={handleBack}>
                        Back
                </ActionButton>
                <ActionButton 
                    variant='contained' 
                    sx={{width:120}}
                    onClick={handleNext}>
                        Next
                </ActionButton>
            </Box>

        </HaloModal>
    )



}