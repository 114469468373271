import { createSlice } from "@reduxjs/toolkit"
import dayjs from "dayjs";

const adminSlice = createSlice({
    name: 'patients',
    initialState: {
        currentAdminTab: '',
        patientListFilters: {
            pagination: { page: 0, pageSize: 10 },
            sites: [],
            ordering: '-id',
            search: ''
        },
        qualityListFilters: {
            pagination: { page: 0, pageSize: 10 },
            ordering: '-id',
            review_state: '0,1',
            search:''
        },
        rosterMgmtDates: [dayjs().format('YYYY-MM-DD'), null],
        rosterMgmtPreset: 'Today',
        rosterMgmtStatSpotlight: 'Patients',
        rosterMgmtGrids:{
            pageSize: 25 , //used for all grid in the RM Admin flow
            dailyCasesFilters: {
                pagination: { page: 0},
                ordering: '-id',
                resolution_status: '',
                sites:[],
                case_type:'',
                user: {id:'', name:''},
                search: ''
            }
        },
        trialUserListFilters: {
            pagination: { page: 0, pageSize: 10 },
            ordering: 'trial_end',
            review_state: 'To-do',
            search: ''
        }
    },
    reducers: {
        setPatientListFilters: (state, action) => {
            state.patientListFilters = {
                ...state.patientListFilters, // Keep existing fields
                ...action.payload, // update fields
            };
        },
        setQAListFilters: (state, action) => {
            state.qualityListFilters = {
                ...state.qualityListFilters,
                ...action.payload
            }
        },
        setTrialUserFilters: (state, action) => {
            state.trialUserListFilters = {
                ...state.trialUserListFilters,
                ...action.payload
            }
        },
        setRosterMgmtPageSize: (state, action) => {
            state.rosterMgmtGrids.pageSize = action.payload
        },
        setDailyCasesFilters: (state, action) => {
            state.rosterMgmtGrids.dailyCasesFilters = {
                ...state.rosterMgmtGrids.dailyCasesFilters,
                ...action.payload
            }
        },
        setAdminTab: (state, action) => {
            state.currentAdminTab = action.payload
        },
        setRosterMgmtDates: (state, action) => {
            state.rosterMgmtDates = action.payload
        },
        setRosterMgmtPreset: (state, action) => {
            state.rosterMgmtPreset = action.payload
        },
        setRosterMgmtStat: (state, action ) => {
            state.rosterMgmtStatSpotlight = action.payload
        }
    }
})


export const {
    setPatientListFilters, setQAListFilters, setTrialUserFilters, 
    setAdminTab, setRosterMgmtDates, setRosterMgmtStat, 
    setDailyCasesFilters, setRosterMgmtPreset, setRosterMgmtPageSize,
} = adminSlice.actions

export const selectPatientListFilters = (state) => state.admin.patientListFilters
export const selectQAListFilters = (state) => state.admin.qualityListFilters
export const selectTrialUserListFilters = (state) => state.admin.trialUserListFilters
export const selectCurrentAdminTab = (state) => state.admin.currentAdminTab
export const selectRosterMgmtDates = (state) => state.admin.rosterMgmtDates
export const selecteRosterMgmtPreset = (state) => state.admin.rosterMgmtPreset
export const selectRosterMgmtStatSpotlight = (state) => state.admin.rosterMgmtStatSpotlight
export const selectRosterMgmtPageSize = (state) => state.admin.rosterMgmtGrids.pageSize
export const selectDailyCasesFilters = (state) => state.admin.rosterMgmtGrids.dailyCasesFilters

export default adminSlice.reducer;
