import { useTheme } from "@emotion/react";
import HeaderFrameLayout from "../../../../../globalComponents/Headers/HeaderFrames/FrameLayout/FrameLayout";
import dayjs from "dayjs";
import { Chip } from "@mui/material";
import CaseStatusChip from "../../../../../rosterManagement/utils/StatusChips/CaseStatusChip";
import { stringToSentenceCase } from "../../../../../../utils/stringUtils/sentenceCase";






export default function QAClinicianFrame({ encounter, trackedDuration, qaObj }){
    const { 
        time_spent_reviewing_entered,
        date_submitted,
        patient,
        treating_provider,
    } = encounter || {}
    
    const theme = useTheme()

    const firstRow = [ 
        { label:'Submitted', value: date_submitted?  dayjs(date_submitted).format('LL') : '-' }, 
        { label:'Self reported duration', value: time_spent_reviewing_entered}, 
        { label:'Tracked duration', value: trackedDuration}, 
        { label:'QA Status', value: <CaseStatusChip caseStatus={stringToSentenceCase(qaObj?.review_state_string)}/>}
    ]
    
    const secondRow = [ 
        { label:'Treating Provider', value: treating_provider}, 
        { label:'Site', value: patient?.site?.site_name }, 
    ]

    return(
        <HeaderFrameLayout 
            avatarColor={theme.avatar.primary}
            name={encounter?.clinician_name}
            firstRow={firstRow}
            secondRow={secondRow}
        />
    )



}