import CoronavirusOutlinedIcon from '@mui/icons-material/CoronavirusOutlined';
import { ReactComponent as Prescription } from '../../../../../../../../assets/icons/prescriptions.svg'
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { TimelineDot } from '@mui/lab';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';


export function getEntryIcon(type, theme){

    const iconSx = {
        p:1,
        boxShadow:'none', 
        '& svg': {
            height:20,
            width:20
        }
    }


    if(type === 'Pathology Encounter'){
        return(
            <TimelineDot sx={{background:theme.palette.primary.main, ...iconSx}}>
                        <Prescription style={{color:theme.palette.background.paper}}/>
            </TimelineDot>
        )
    }
    else if(type === 'Infectious Disease Encounter'){
        return (
            <TimelineDot sx={{background:theme.palette.warning.light, ...iconSx}}>
                        <CoronavirusOutlinedIcon sx={{color:theme.palette.background.paper}}/>
                </TimelineDot>
            )
    }
    else if (type === 'IPC'){
        return (
            <TimelineDot sx={{background:theme.palette.success.main, ...iconSx}}>
                        <PhoneIphoneIcon />
            </TimelineDot>
        )
    }
    else{
        return(
            <TimelineDot sx={{background:theme.palette.text.secondary, ...iconSx}}>
                        <StickyNote2OutlinedIcon />
            </TimelineDot>
        )
    }

}