import { useParams } from "react-router-dom"
import { useGetCaseQuery } from "../rosterAPISlice"
import InfectiousDiseaseSummaryContainer from "../../infectiousDisease/components/infectiousDiseaseReview/InfectiousDiseaseSummaryContainer"
import PathologySummaryContainer from "../../pathology/components/PathologyReview/PathologySummaryContainer"
import CaseSummaryNav from "../components/CaseNavHeaders/CaseSummaryNav"




export default function CaseSummary(){
    let {caseType, caseId} = useParams()
    //recalling encounter data in case state is out of sync
    const {data, isLoading, isFetching} = useGetCaseQuery(caseId, { refetchOnMountOrArgChange: true }) 

    return(
        <>
        <CaseSummaryNav 
            caseId={caseId} 
            caseType={caseType}
            isLoading={isLoading || isFetching}
            patientName={data?.encounter?.patient?.name}
            isResubmitting={Boolean(data?.encounter?.date_submitted)} />
        
        {
            caseType === 'Infectious Disease'?
                <InfectiousDiseaseSummaryContainer data={data} isLoading={isLoading || isFetching} allowEdits/>
                :
                <PathologySummaryContainer data={data} isLoading={isLoading || isFetching} allowEdits/>
        }

        </>
    )
}
