import { Box, Divider, IconButton, LinearProgress, Typography } from "@mui/material";
import dayjs from "dayjs";
import TuneIcon from '@mui/icons-material/Tune';
import { TargetIcon } from "../../../../../assets/icons/Target";

const getGoalHelperText = ({targetPoints, currentPoints}) => {

    let diff = Math.trunc(currentPoints - targetPoints);
    let msg;

    if( diff > 0){
        msg = `Your're ${diff} billing points ahead of schedule!`
    }
    else if (diff<0){
        diff = Math.abs(diff)
        msg = `Your're ${diff} billing points behind schedule`
    }
    else if (diff === 0 && targetPoints > 0){
        msg = `You're right on schedule!`
    }

    return msg
}


const dayCnt = dayjs().day() + 1


export default function DashboardPointsGoal({
    billingPoints, 
    pointsGoalObj, 
    openModal, 
    weekRangeStr,
    prefetchSchedules
}){

    const {points_goal, opt_out} = pointsGoalObj || {}

    const normalise = (value) => ((value - 0) * 100) / (points_goal - 0); //scales the points goal
    const currentPoints = billingPoints !== null && billingPoints !== undefined ? billingPoints : '-' //show zero
    const targetPoints = (points_goal / 7) * dayCnt

    return(
        <Box sx={(theme) => ({...theme.standardBox})}>
            <Box className='flex-row-between-center' p='12px 16px'>
                <Box>
                    <Typography variant="caption" color='text.secondary'>{weekRangeStr}</Typography>
                    <Typography variant="subtitle2">My goal</Typography>
                </Box>
                <IconButton onClick={openModal} sx={{height:28, width:28, p:0.5}} onMouseEnter={prefetchSchedules} title='Edit schedule'>
                    <TuneIcon sx={{fontSize:20}}/>
                </IconButton>
            </Box>
            <Divider/>
            <Box className='flex-col' p={'8px 16px 16px 16px'}>
                {
                    targetPoints && !opt_out?
                <>
                    <Typography variant="h6" fontWeight={500} color='success.main'> {currentPoints} / {points_goal || '-'}</Typography>
                    <LinearProgress 
                        variant="buffer" 
                        color="success" 
                        value={billingPoints > points_goal ? 100: normalise(billingPoints) || 0} 
                        valueBuffer={billingPoints > points_goal ? 0 :  normalise(targetPoints)}
                        sx={(theme) =>({
                            height:8,
                            borderRadius:1,
                            '.MuiLinearProgress-dashed':{
                                background: theme.palette.action.hover,
                                animation:'none'
                            }
                        })}/>
                    <Typography variant="caption" 
                        color='success.main'
                        sx={{
                            mt:1,
                            letterSpacing:'0.15px'
                        }}
                    >{getGoalHelperText({targetPoints, currentPoints})}</Typography>    
                </>
                :
                <>

                    <TargetIcon sx={{ fontSize: 40, m:'auto' }} color='disabled'/>
                    <Typography variant="caption" color='text.disabled' alignSelf='center' mt={0.5}>No goal yet</Typography>
                </>
                }
            </Box>
        </Box>
    )

}