import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import DataInput from './DataInput/DataInput';
import Samples from './Samples/Samples';
import Recommendations from "./Recommendations/Recommendations";


export default function InfectiousDiseaseSummaryContainer({ data, isLoading, allowEdits }) {
  const { caseType, caseId } = useParams();


  const { encounter, med_recs_options } = data || {};
  const { patient, general, samples, medications, d2d_interactions } = encounter || {};

  const caseInfo = { 
    case_type: caseType,
    case_id: caseId
  };


  return (
    <Box className='flex-col' gap={3} p={3}>
      {encounter && (
        <>
          <DataInput
            allergies={patient?.allergies}
            client={general}
            diagnoses={patient?.icd10_references}
            readOnlyView={true}
            allowEdits={allowEdits}
            caseInfo={caseInfo}
            isLoading={isLoading}
          />
          <Samples
            initSamples={samples}
            readOnlyView={true}
            allowEdits={allowEdits}
            caseInfo={caseInfo}
            isLoading={isLoading}
          />
          <Recommendations
            medInstructions={medications}
            general={general}
            drugInteractions={d2d_interactions}
            med_recs_options={med_recs_options}
            readOnlyView={true}
            allowEdits={allowEdits}
            caseInfo={caseInfo}
            isLoading={isLoading}
          />
        </>
      )}
    </Box>
  );
}
