import { useTheme } from "@emotion/react"
import { Box, Typography } from "@mui/material"
import dayjs from "dayjs"
import { useMemo } from "react"







export default function QACommentsContainer({
    sectionTitle = '',
    timeStamp = null,
    comments = '',
    fieldReview,
    approved=false,
}){


    const theme = useTheme()
    const { score_percentage, feedback, section_name } = fieldReview || {}

    const displayScoreOrDate = useMemo(() => {

        if (score_percentage || score_percentage === 0) return score_percentage + '%'

        return dayjs(timeStamp).format("MMM DD, YYYY h:mm A");

    }, [score_percentage, timeStamp]);

    return(
        <Box className='flex-col' 
                sx={{ 
                    p:'12px 16px', 
                    borderRadius: 1, 
                    background: score_percentage === 100 || approved? theme.palette.success.shades4 : theme.palette.error.shades4 ,
                    border:1,
                    borderColor:theme.palette.divider
                }}>
                <Typography sx={{...theme.typography.caption, color:theme.palette.grey[500]}}>
                    {section_name?  section_name + " Comments" : sectionTitle} • {displayScoreOrDate}

                </Typography>
                <Typography variant="body1">{ feedback || comments || '-'}</Typography>
            </Box>
    )
}