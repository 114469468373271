import { Box, Divider, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from "@emotion/react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";







export default function HaloModal({
    open, 
    handleClose,
    title,
    subtitle,
    modalMaxWidth,
    children,
    ...otherProps
}){

    const theme = useTheme()

    return(
        <Modal open={open} disableEnforceFocus {...otherProps}>
            <Box sx={{...theme.standardBox, maxWidth:modalMaxWidth || 600, maxHeight:'91vh',  ...theme.modal.centered}}>
                <Box className='flex-row-between-center' p={'12px 16px'} maxHeight={64}>
                    <Box className='flex-col'>
                        <Typography variant="subtitle2">{title}</Typography>
                        <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
                            {subtitle}
                        </Typography>
                    </Box>
                    <IconButton onClick={handleClose} size="small" sx={{height:32, width:32}}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
                <Divider/>
                <OverlayScrollbarsComponent defer 
                    className="flex-col"
                    style={{
                        overflow:'auto', maxHeight:'calc(91vh - 67px)'
                    }}>
                    {children}
                </OverlayScrollbarsComponent>
            </Box>
        </Modal>
    )
}
