
import { useTheme } from '@emotion/react';
import { Box, Collapse, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import PatientDetails from './PatientDetails/PatientDetails';
import PatientDocs from './PatientDocs/PatientDocs';
import HeaderCollapseBtn from './HeaderCollpaseBtn';


export default function PatientInfo({ patient, paginated_lab_history, paginated_encounter_history, children }) {
    const theme = useTheme()
    const [show, setShow] = useState(true)
    const [showShadow, setShowShadow] = useState(false)

    const toggleShow = () => setShow(!show)

    useEffect(() => {
        function handleScroll() {
            const scrollPosition = window.scrollY;
            setShowShadow(scrollPosition > 70);
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {/* spacer for header */}
            <Box height={!show ? 64 : 251} width={'100%'} sx={{ transition: 'height 0.3s ease' }}></Box>

            <Box sx={{
                background: theme.palette.background.paper,
                boxShadow: showShadow || show ? theme.customShadows.elevation4 : '',
                ...theme.stickyHeader,
            }}>
                {children}
                <Collapse
                    in={show}>
                    <Box className='flex-row' p={3} pt={2} gap={3}>
                        <Box className='flex-col' gap={1} flex={1} maxWidth={725}>
                            <PatientDetails
                                patient={patient}
                                theme={theme}
                            />
                        </Box>
                        <Divider orientation='vertical' flexItem />
                        <Box className='flex-col' flex={1}>
                            <PatientDocs
                                paginated_lab_history={paginated_lab_history}
                                paginated_encounter_history={paginated_encounter_history} />
                        </Box>
                    </Box>
                </Collapse>
                <HeaderCollapseBtn show={show} showShadow={showShadow} toggleShow={toggleShow} />
            </Box>

        </>
    )
}
