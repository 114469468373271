import { useTheme } from "@emotion/react"
import { Alert, AlertTitle, Box, Divider, Skeleton, Typography } from "@mui/material"
import GradingSection from "./GradingSection/GradingSection"
import HaloSkeleton from "../../../../globalComponents/Skeletons/HaloSkeleton"
import { useRef, useState } from "react"
import { useSubmitQAReviewMutation } from "../../../qualityAPISlice"
import { show400Error } from "../../../../../app/errors/genericErrors"
import { useNavigate } from "react-router-dom"
import { enqueueSnackbar } from "notistack"
import GradingButtons from "./GradingButtons.jsx/GradingButtons"
import GeneralFeedback from "./GradingSection/GeneralFeedback"
import ScoreAndStatus from "./ScoreaAndStatus/ScoreAndStatus"
import GSLoader from "./GradingSection/GSLoader"

//// for front-end reference
// 0 = approved
// 1 = needs edits
// 2 = need complete revision/new clincian 


export default function QAReviewGrading({
    qaObj, 
    loading, 
    readOnly,
    patient,
    currentClinician
}){

    const theme = useTheme()
    const navigate = useNavigate()
    const { field_reviews, final_feedback  } =  qaObj || {}
    const [ rejectMenuOpen, setRejectMenuOpen ] = useState(false);
    const [submitQA, {isLoading:isSubmitting }] = useSubmitQAReviewMutation()
    const anchorRef = useRef(null);

    const handleRejectToggle = () => {
        setRejectMenuOpen((prevOpen) => !prevOpen);
    };
   

    const handleDecision = (decision, values={}) => (e) =>{
        setRejectMenuOpen(false)
        submitQA({
            qualityId:qaObj?.id,
            payload:{
                final_decision: decision,
                ...values
            }
        })
        .unwrap()
        .then(res => {
            enqueueSnackbar('QA review submitted', {variant:'success'})
            navigate('/admin')
        })
        .catch(err => show400Error(err))

    }
    

    return(
        <Box className='flex-col' gap={2} mt={3} mb={10} >
            <Box sx={{ ...theme.standardBox, flex:1, gap:2 }} >
                <Box p={2} >
                    <HaloSkeleton loading={loading}>
                        <Typography variant="subtitle2">Grading</Typography>
                    </HaloSkeleton>
                </Box>
                <Divider />
                <Box className='flex-col' sx={{ p:2, pt:2.5, pb:2, gap:2.5, }}>
                    {
                        !loading ?
                            <>
                            {field_reviews?.map((field) =>(
                                <GradingSection
                                key={field?.id}
                                section={field}
                                qualityId={qaObj?.id}
                                disabled={readOnly}
                                loading={loading}
                                />
                            ))}
                            <Divider sx={{pt:1}}/>
                            <Box className='flex-col' gap={3}>
                                <ScoreAndStatus qaObj={qaObj} loading={loading}/>
                                <GeneralFeedback 
                                    qaObj={qaObj}   
                                    loading={loading}
                                    disabled={readOnly}
                                    />
                            </Box>
                            </>
                        :
                        <>
                        <GSLoader />
                        <GSLoader />
                        <GSLoader />
                        <GSLoader />
                        </>

                    }
                </Box>
                
                {
                    !readOnly &&
                    <GradingButtons 
                        rejectMenuOpen={rejectMenuOpen}
                        handleRejectToggle={handleRejectToggle}
                        handleDecision={handleDecision}
                        anchorRef={anchorRef}
                        queryLoading={loading}
                        isSubmitting={isSubmitting}
                        finalFeedback={final_feedback}
                        patient={patient}
                        currentClinician={currentClinician}
                    />
                }
                

            </Box>
            <Alert severity="info" sx={{ borderRadius: 2 }}>
                <AlertTitle>Submission Evaluation Guidelines</AlertTitle>
                • Score ≥ 90: Mark as "Approved."<br />
                • Score 75 - 89: Use clinical discretion.<br />
                • Score &lt;75: Choose "Edit &amp; Resubmit" or "Reassign."
            </Alert>
        </Box>
    )


}