import { useParams } from "react-router-dom"
import { useGetCasePerformanceStatsQuery } from "../../../../rosterAdminAPISlice"
import { useDispatch, useSelector } from "react-redux"
import { selectRosterMgmtDates, selectRosterMgmtStatSpotlight, setRosterMgmtStat } from "../../../../../admin/adminSlice"
import StatCard from "../../../../../globalComponents/Cards/StatCard/StatCard"
import { Box } from "@mui/material"



export function ClinicianStatCards(){

    const {clinicianId} = useParams()
    const dispatch = useDispatch()
    const dates = useSelector(selectRosterMgmtDates)
    const stat = useSelector(selectRosterMgmtStatSpotlight)

    const {data, isLoading, isFetching} = useGetCasePerformanceStatsQuery({dates, userId: clinicianId})
    const { requested, requested_change, assigned, assigned_change, completed, completed_change, day_period, session_duration, session_duration_change } = data || {}

    const selected = (label) => stat === label
    const setStat = (val) => (e) => dispatch(setRosterMgmtStat(val))


    return(
        <Box className='flex-row' gap={1} flexWrap='wrap'>
            <StatCard 
                label='Case duration'
                stat={session_duration}
                percentDiff={session_duration_change}
                selected={selected('Case duration')}
                setStat={setStat('Case duration')}
                dayPeriod={day_period}
                dates={dates}
                loading={isLoading || isFetching}/>
            <StatCard
                label='Requested'
                stat={requested}
                percentDiff={requested_change}
                selected={selected('Requested')}
                setStat={setStat('Requested')}
                dayPeriod={day_period}
                dates={dates}
                loading={isLoading || isFetching}/>
            <StatCard 
                label='Assigned'
                stat={assigned}
                percentDiff={assigned_change}
                selected={selected('Assigned')}
                setStat={setStat('Assigned')}
                // endComponent={<AsgmtStatChart  dates={dates}/>}
                dayPeriod={day_period}
                dates={dates}
                loading={isLoading || isFetching}
                />
            <StatCard 
                label='Completed'
                stat={completed}
                percentDiff={completed_change}
                selected={selected('Completed')}
                setStat={setStat('Completed')}
                dayPeriod={day_period}
                dates={dates}
                loading={isLoading || isFetching}/>
        </Box>
    )
}