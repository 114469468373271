
import {  useManageGeneralEncounterMutation } from "../../../../infectiousDiseaseAPISlice";
import { showGenericValidationError } from "../../../../../../app/errors/genericErrors";
import { Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { HaloTextField } from '../../../../../globalComponents/ReactHookFormFields'
import MedDisclosureSearch from "./MedDisclosureSearch";
import { ClinicalNotesIcon } from "../../../../../../assets/icons/MaterialDesignIcons"
import { getToolbarStatusIcon } from "../../../../../globalComponents/DataGrid/utils/getToolbarStatusIcon";


export default function MedicationNotes({readOnly, general}){


    const [updateNotes] = useManageGeneralEncounterMutation()
    const {control, handleSubmit, formState:{ isDirty, isValid }, reset, setValue, getValues} = useForm({
        defaultValues:{
            treating_provider: general?.treating_provider,
            notes_from_pharmacist: general?.notes_from_pharmacist,

        }
    })


    const handleSave = (values) =>{
        if(isDirty){
            updateNotes({
                method:'PUT',
                body:{
                    id: general.encounter, 
                    ...values
                }
            })
            .unwrap()
            .then(res => reset( {...values}, {keepDirtyValues:true, keepDirty:false, }))
            .catch(err => showGenericValidationError(err))
        }
        
    }



    return(
        
        <Box 
            className='flex-col'  
            gap={2} 
            sx={(theme)=>({...theme.standardBox})}
            onBlur={handleSubmit(handleSave)}>
                <Box
                    className='flex-row'
                    sx={(theme) => ({
                        p:'8px 16px',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        gap:0.5,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        height:48,
                        backgroundColor: 'primary.shades4',
                    })}>
                        {<ClinicalNotesIcon color='primary' style={{fontSize:22}}/>}
                        <Typography variant="body1" color='text.primary' > Additional considerations </Typography>
                        {!readOnly && getToolbarStatusIcon(!isValid)}
                </Box>
                
            <Box className='flex-col' gap={2} flex={2} p={2} pt={0} >
                <HaloTextField 
                    name='treating_provider'
                    label='Treating Provider'
                    disabled={readOnly}
                    hideHelperText
                    maxLength={300}                
                    control={control}
                    />
                {
                    !readOnly &&
                    <MedDisclosureSearch 
                        setRHFFields={setValue}
                        getRHFFields={getValues}
                        handleSave={handleSave}
                        />
                }
                <HaloTextField 
                    name='notes_from_pharmacist'
                    label='Notes from pharmacist'
                    disabled={readOnly}
                    multiline
                    minRows={5}
                    maxRows={30}
                    maxLength={7000}
                    control={control}
                />
                
            </Box>
        </Box>
    )
}