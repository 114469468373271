import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getIsAuthStatus, selectCurrentUser, setCredentials, getIsRouteForbidden } from './authSlice';
import axiosAuth from '../../utils/apiUtils/axiosAuth';
import { keysToCamelCase } from '../../utils/stringUtils/camelCase';
import { setAdminTab } from '../admin/adminSlice';
import { enqueueSnackbar } from 'notistack';

const useAuthStatus = () => {
    const isAuth = useSelector(getIsAuthStatus);
    const user = useSelector(selectCurrentUser)
    const isForbidden = useSelector(getIsRouteForbidden)
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const verifyUser = useCallback(async () => {
        try {
            const res = await axiosAuth.get(`${process.env.REACT_APP_API_URL}auth-check`);
            if (res?.data) {
                const user = keysToCamelCase(res.data.user);
                dispatch(setCredentials({
                    user,
                    isAuthenticated: Boolean(user)
                }))
                dispatch(setAdminTab(
                    user?.groups?.adminGroupTabs[0]
                ))

                //used to display server side snackbar alerts 
                //e.g. google callbaack, bad logins, etc.
                if(res.data?.message){
                    const {message} = res.data
                    enqueueSnackbar(message.message, {variant:message.type})
                }

            }
        } catch (error) {
            // Handle error (e.g., log it, show a message to the user)
            console.error('Error verifying user');
        } finally {
            setLoading(false);
        }
    }, [dispatch]);


    
    useEffect(() => {
        if (!isAuth) {
            verifyUser();
        } else {
            setLoading(false);
        }
    }, [isAuth, verifyUser]);

    return { isAuth, loading, user, isForbidden };
};

export default useAuthStatus;
