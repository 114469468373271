import { useTheme } from "@emotion/react"
import { SmallHeaderBox, TypographySubtitle } from "../../../../globalComponents/SubHeader/components"
import BackButton from "../../../../globalComponents/SubHeader/Backbutton"
import { Avatar, Box, Collapse, Divider } from "@mui/material"
import QAClinicianFrame from "./Frames/QAClinicianFrame"
import PatientFrame from "../../../../patients/components/ClinicianPatientHeader/HeaderFrames/PatientFrame"
import { useState } from "react"
import { CaseHeaderLoader, SubHeaderLoader } from "../../../../globalComponents/Loaders"
import { stringToTitleCase } from "../../../../../utils/stringUtils/titleCase"
import DocDownloadBtn from "../../../../rosterManagement/components/DocDownloadBtn/DocDownloadBtn"







export default function QAReviewHeader({ encounter, qaObj, patient, trackedDuration, loading }){

    const theme = useTheme()
    const [isExpanded, setIsExpanded] = useState(false)

    if(loading){
        return(
            <>
                <SubHeaderLoader/>
                <CaseHeaderLoader/>
            </>
        )
    }


    return(
        <>
        <Box height={193} width={'100%'}></Box> 
        <Box 
            onMouseEnter={() => setIsExpanded(true)}
            onMouseLeave={() => setIsExpanded(false)}
            sx={{
                ...theme.stickyHeader, 
                borderBottom:`1px solid ${theme.palette.divider}`
                }}>
            <SmallHeaderBox justifyContent="flex-row-between">
                <BackButton/>
                <TypographySubtitle>{stringToTitleCase(encounter?.clinician_name) + "'s case submission" }</TypographySubtitle>
                <Box ml='auto'>
                    <DocDownloadBtn
                        encounterId={encounter?.id}
                        isInfecDisCase={false}
                    />
                </Box>
            </SmallHeaderBox>
            <Collapse
                in={isExpanded}
                collapsedSize={127}
                >
                <Box 
                    className='flex-row'
                    sx={{
                        p:3,
                        pt:2,
                        gap:3,
                        background:theme.palette.background.paper,
                    }}>
                        <QAClinicianFrame 
                            encounter={encounter}
                            trackedDuration={trackedDuration}
                            qaObj={qaObj}
                        />
                        <Divider orientation="vertical" flexItem sx={{ height: isExpanded? 'auto' : 100, }}/>
                        <PatientFrame patient={patient}/>
                </Box>
            </Collapse>
        </Box>
        </>
    )

}