import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentCase } from "../rosterSlice";
import PathologyFinalizeContainer from "../../pathology/components/PathologyReview/PathologyFinalizeContainer";
import InfectiousDiseaseFinalizeContainer from "../../infectiousDisease/components/infectiousDiseaseReview/InfectiousDiseaseFinalizeContainer";
import SuccessMessage from "../components/CaseSuccessMessage/SuccessMessage";
import CaseFinalizationNav from "../components/CaseNavHeaders/CaseFinalizationNav";
import { useUpdateCaseStatus } from "../hooks/useUpdateCaseStatus";
import { useGetCaseQuery } from "../rosterAPISlice";
import dayjs from "dayjs";
import { calculateAge } from "../../../utils/dateUtils/calculateAge";




export default function CaseFinalization(){
    let {caseType, caseId} = useParams()
    const patient = useSelector(selectCurrentCase)?.patient
    const [isSubmitted, setSubmitted] = useState(false)
    const {data, isLoading, isFetching} = useGetCaseQuery(caseId, { refetchOnMountOrArgChange: true })
    const updateCaseStatus = useUpdateCaseStatus()

    const isUnder18 = calculateAge(dayjs(patient?.date_of_birth)) < 18

    useEffect(() =>{

        if(isSubmitted){
            updateCaseStatus(caseId,patient.id, 'completed')
        }

    },[isSubmitted])


    //will there be a difference in submission screen between path and infec?
    return(
        <>
            <CaseFinalizationNav isSubmitted={isSubmitted} patientName={patient.name} isLoading={isLoading || isFetching}/>

            <Box p={3}>
                {isSubmitted?
                    <SuccessMessage caseType={caseType} patient={patient} encounterId={data?.encounter?.id}/>
                    :
                    caseType === 'Infectious Disease'?

                    //these could be simplified, they are very similar
                    <InfectiousDiseaseFinalizeContainer 
                        isUnder18={isUnder18}
                        isLoading={isLoading || isFetching}
                        setSubmitted={setSubmitted} 
                        treatingProvider={data?.encounter?.general?.treating_provider}
                        sessionDuration={data?.total_session_duration_in_minutes}/>  
                    : 
                    <PathologyFinalizeContainer 
                        isUnder18={isUnder18}
                        isLoading={isLoading || isFetching}
                        setSubmitted={setSubmitted} 
                        treatingProvider={data?.encounter?.treating_provider}
                        sessionDuration={data?.total_session_duration_in_minutes}/>      

                }
            </Box>
        </>
    )
}
