import { Box, Button, Collapse } from "@mui/material";
import SampleForm from "./subComponents/SampleForm/SampleForm";
import Organisms from "./subComponents/Organisms/Organisms";
import AntibioticResistances from "./subComponents/AntibitoicResistance/AntibioticResistances";
import { useManageSamplesMutation } from "../../../../infectiousDiseaseAPISlice";

import { useEffect, useState } from "react";
import FormToolbar from "./subComponents/SampleForm/FormToolbar";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";




export default function SampleComponent({
    sample,
    samplesState, 
    setSamplesState, 
    readOnly=false, 
    sample_menu_options}){

    const {sample_type_options, organism_options, microbial_load_options, gene_options, key, unit_label_options } = sample_menu_options || {}
    const {encouter, ...sampleFields} = sample || {}
    
    const [open, setOpen] = useState(true)
    const [manageSample] = useManageSamplesMutation()
    const { control, handleSubmit, formState:{isDirty, errors}, reset} = useForm({
        defaultValues:{ 
            ...sampleFields,
            sample_date: sampleFields?.sample_date? dayjs(sampleFields.sample_date) : dayjs().subtract(1, 'day')
        }
    }) 
    
    // keep local form state in sync when parent array holding all samples gets updated, redundant
    useEffect(() => {
        reset({
            ...sampleFields, 
            sample_date: sampleFields?.sample_date? dayjs(sampleFields.sample_date) : dayjs().subtract(1, 'day')
        })
    }, [sample])
    


    return (
        <Box sx={(theme) => ({...theme.standardBox})} key={key}>
            <FormToolbar 
                manageSample={manageSample}
                sample={sample}
                setOpen={setOpen}
                open={open}
                setSamplesState={setSamplesState}
                control={control}
                readOnly={readOnly}
                errors={errors}/>
            <Collapse in={open} timeout={{exit:150, enter:300}}>
                <Box className='flex-col' sx={(theme) =>({ borderTop: `1px solid ${theme.palette.divider}`, gap:'18px', p:2 })}>
                    <SampleForm 
                        sample={sample}
                        readOnly={readOnly}
                        sample_type_options={sample_type_options}
                        manageSample={manageSample}
                        control={control}
                        handleSubmit={handleSubmit}
                        isDirty={isDirty}
                        reset={reset}
                        /> 
                    <Box className='flex-col' gap={'10px'}>
                        <Organisms 
                            readOnly={readOnly} 
                            sample={sample}  
                            organism_options={organism_options}
                            unit_label_options={unit_label_options}
                            microbial_load_options={microbial_load_options}/>
                        <AntibioticResistances 
                            readOnly={readOnly} 
                            sample={sample}
                            gene_options={gene_options} />
                    </Box>
                </Box>
            </Collapse>
        </Box>
    )
}