import { useState, useEffect } from "react";





//still relevant? kind of prefer the lodash one
const useDebounce = ( val, delay ) =>{
    const [debouncedValue, setDebouncedValue] = useState(val);
    

    useEffect(() => {
          // Update debounced value after delay
          const handler = setTimeout(() => {
            setDebouncedValue(val);
          }, delay);
          // Cancel the timeout if value changes (also on delay change or unmount)
          // This is how we prevent debounced value from updating if value is changed ...
          // .. within the delay period. Timeout gets cleared and restarted.
          return () => {
            clearTimeout(handler);
          };
        },[val, delay]);
      return debouncedValue

}

export default useDebounce;