import { Box, Divider } from "@mui/material";
import { selectCurrentUser } from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import AdminHeader from "../components/AdminHeader/AdminHeader";
import PatientListPanel from "../../patients/admin/components/PatientListPanel/PatientListPanel";
import QAReviewPanel from "../../qualityAssurance/components/QAReviewPanel/QAReviewPanel";
import TrialUserListPanel from "../../trialUsers/components/TrialUserPanel/TrialUserListPanel";
import { selectCurrentAdminTab, setAdminTab } from "../adminSlice";
import RosterAdminPanel from "../../rosterManagement/admin/components/RosterAdminPanel/RosterAdminPanel";
import AdminTabs from "../components/Tabs/AdminTabs";



export default function AdminHome(){

    const dispatch = useDispatch()
    const user = useSelector(selectCurrentUser)
    const tabValue = useSelector(selectCurrentAdminTab) 
    

    const handleTabChange = (event, newValue) => dispatch(setAdminTab(newValue))
    
    return(
        <>
        <AdminHeader user={user} tabValue={tabValue}/>
        <Box sx={(theme) => ({background:theme.palette.background.paper})}>

            <AdminTabs
                tabValue={tabValue}
                handleTabChange={handleTabChange}
                userGroups ={user.groups}
                />
            <Divider/>
        </Box>

        <Box className='flex-col' p={3} gap={2} >

        {
            tabValue === 'Roster Mgmt.'?
                <RosterAdminPanel user={user}/>
            :
            tabValue === 'Patients'?
                <PatientListPanel user={user}/>
            : 
            tabValue === 'Trial users' ? 
                <TrialUserListPanel user={user}/> 
            :        
            tabValue === 'QA cases' ?
                <QAReviewPanel user={user}/>
            :
            null
        }
        </Box>



        </>

    )
}
