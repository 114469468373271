import CaseStatusChip from "../../../../rosterManagement/utils/StatusChips/CaseStatusChip"





export  const AssignmentStatusChipCell = ({row}) => {

        const {case_resolution_status:status, qa_status} = row || {}
        return <CaseStatusChip caseStatus={qa_status === 'Rejected' ? qa_status : status} qaStatus={qa_status}/>

}