import { Box, Typography } from "@mui/material";
import { getIsDateRange } from "../../../../../globalComponents/DateRange/utils/getIsDateRange";
import { dateRangeToString } from "../../../../../../utils/dateUtils/dateRangeToString";
import { useSelector } from "react-redux";
import { selectRosterMgmtDates, selectRosterMgmtStatSpotlight } from "../../../../../admin/adminSlice";
import ResponsiveBarChart from "../../../../../globalComponents/NivoCharts/ResponsiveBar/ResponsiveBarChart";
import CalendarIcon from '../../../../../../assets/icons/Calendar.svg'
import { useGetCaseStatsBarChartsQuery } from "../../../../rosterAdminAPISlice";


const getPadding = (dataSetCount) =>  dataSetCount > 7 ? 0.3 : 0.7



export function ClinicianStatSpotlight({clinicianId}){

    const dates = useSelector(selectRosterMgmtDates)
    const stat = useSelector(selectRosterMgmtStatSpotlight)

    const isDateRange = getIsDateRange(dates)
    const {data} = useGetCaseStatsBarChartsQuery({dates, statType:stat, userId:clinicianId}, {skip: !isDateRange})



    return(
        <Box sx={(theme) => ({ ...theme.standardBox, height:346, p:2})}>
            <Box className='flex-row-between'>
                <Box className='flex-col'>
                    {
                    isDateRange &&
                        <>
                            <Typography variant="subtitle2">{stat}</Typography>
                            <Typography variant="body2">{dateRangeToString(dates[0], dates[1])}</Typography>
                        </>
                    }
                </Box>
            </Box>     
           {
            isDateRange?
            <Box height={229} width={'100%'} mt={'31px'}>
                
                    <ResponsiveBarChart
                        data={data || []} 
                        padding={getPadding(data?.length)}
                        axisBottom={{tickSize:0, tickPadding:8}}
                        enableLabel={false}/> 
               
            </Box>  
            :
            <Box className='flex-col-center' m={'auto'} justifyContent={'center'} gap={2} width={184} height={'100%'}>
                <img src={CalendarIcon} height={74} width={64}/>
                <Typography variant="subtitle2" color={'text.disabled'} textAlign={'center'}>Please select a date range to enable trend widget</Typography>
            </Box>
            }
              
        </Box>
    )
}