import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { selectRosterMgmtDates } from "../../../../../admin/adminSlice";
import { useGetClinicianCaseAsgmtHistoryQuery } from "../../../../rosterAdminAPISlice";
import LinkedPatientCell from "../../../../../globalComponents/DataGrid/subComponents/customCells/LinkedPatientCell";
import { AssignmentStatusChipCell } from "../../../../../globalComponents/DataGrid/subComponents/customCells/AssignmentStatusChipCell";
import { stringToSentenceCase } from "../../../../../../utils/stringUtils/sentenceCase";




const columns = [
    {
        field: 'case_type',
        headerName: 'Case type',
        width: 130,
    },
    {
        field: 'patient',
        headerName: 'Patient',
        minWidth: 180,
        renderCell: (params) => (
            <LinkedPatientCell
                patientName={params?.value}
                patientId={params?.row?.patient_id}
            />
        )
    },
    {
        field: 'site',
        headerName: 'Site',
        minWidth: 180,
    },
    {
        field: 'case_resolution_status',
        headerName: 'Status',
        minWidth: 180,
        renderCell: (params) =>  <AssignmentStatusChipCell {...params} />
    },
    {
        field: 'case_resolution_notes',
        headerName: 'Notes',
        flex: 2,
        valueFormatter: (value) => value? stringToSentenceCase(value) : '-'
    },
]


export function ClinicianCaseHistory({clinicianId}){

    const dates = useSelector(selectRosterMgmtDates)
    const {data, isLoading, isFetching} = useGetClinicianCaseAsgmtHistoryQuery({dates, clinicianId})


    return(
        <Box sx={(theme) => ({
            '.MuiDataGrid-container--top':{
                borderRadius:2
            },
            ...theme.standardBox,
            })}>
            <DataGridPro  
                rows={data?.results || []}
                columns={columns}
                loading={isFetching || isLoading}
                sx={{border:'none'}}
                autoHeight
            />
        </Box>
    )
}