import { useLogoutMutation } from "../../../../../features/auth/authApiSlice";
import { useDispatch } from "react-redux";
import { Menu, ListItem, ListItemButton, ListItemIcon, ListItemText, Chip } from "@mui/material";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import { logOut } from "../../../../../features/auth/authSlice";










export default function UserMenu({
    open,
    isMenuOpen,
    anchorEl,
    handleClose,
    handleDarkMode
}){


    const [logoutMutation, {isLoading: isLoggingOut}] = useLogoutMutation()
    const dispatch = useDispatch();
  
  
    const logUserOut = async() => {
      logoutMutation()//logout request to the server
        .unwrap()
        .then(res=> dispatch(logOut()) )   
    }
    
    const handleDarkModeClick = () => {
      handleDarkMode()
      handleClose()
    }


    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={isMenuOpen}
            onClose={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mb: 1.5,
                },
            }}
            sx={{width: !open ? '100px' : '400px'}}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            transformOrigin={{horizontal: 'center' , vertical: 'bottom'}}
        >
        {/* Dark Mode Icon */}
         <ListItem disablePadding sx={{ display: "block" }} title='Dark mode'>
            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5 }}  onClick={handleDarkModeClick}>
              <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }} >
                <DarkModeOutlinedIcon/>
              </ListItemIcon>
              <ListItemText primary={'Dark Mode'} sx={{ opacity: open ? 1 : 0 }} />
              {
                open &&
                <Chip label='Beta' color='warning'  size="small" sx={{ml:1 }}/>
              }
            </ListItemButton>
        </ListItem>

        {/* Logout Icon */}
        <ListItem disablePadding sx={{ display: "block" }}  title='Logout'>
            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5 }} onClick={ () => logUserOut()}>
              <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
                <LogoutOutlinedIcon/>
              </ListItemIcon>
              <ListItemText primary={'Logout'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
        </ListItem>
    </Menu>
    )
}