import { useTheme } from "@emotion/react";
import { Box, CircularProgress, Typography } from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import HistoryEntry from "./HistoryEntry/HistoryEntry"
import { memo, useEffect, useState } from "react";
import DefaultNoRowsOverlay from "../../../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay"
import { HistoryEntryLoader } from "./HistoryEntry/HistoryEntryLoader"
import FilterMenu from "./FilterMenu/FilterMenu";
import { useLazyGetPaginatedPatientHistoryQuery } from "../../../../patientAPISlice";


function PatientHistory({
    history, 
    patientId,
    isBottom,
    setIsBottom
    }){

    const theme = useTheme()
    const [historyState, setHistory] = useState(history) 
    const [filter, setFilter] = useState('')

    const [getHistory, {isLoading, isFetching}] = useLazyGetPaginatedPatientHistoryQuery()
    const [extendedLoading, setExtendedLoading] = useState(false) //state to allow for minimum loading time
    
    const loading = isLoading || isFetching || extendedLoading

    const loadMoreHistory = ({newFilter, page}) => {
        setExtendedLoading(true)
        getHistory({
            page: page || historyState.page +1,
            dataType: newFilter || filter,
            patientId
        }).unwrap()
        .then(res => {
            setTimeout(() => {
                if(newFilter){ //reset state if using new filter
                    setHistory({...res})
                }else{
                    setHistory(prev => ({
                        ...res, 
                        data: [...prev.data, ...res.data] 
                    }));
                }
                setExtendedLoading(false)
            }, 1000) //min time the loading state is show to user
        })
        setIsBottom(false)
    }



    const hasHistory = historyState?.data?.length > 0
    const reachedMaxPages = historyState?.page >= historyState?.total_pages
    
    useEffect(()=>{
        const reachedMaxPages = historyState?.page >= historyState?.total_pages

        if(isBottom && !reachedMaxPages &&  !loading){
            loadMoreHistory({})
        }

    },[isBottom])

   

    return(
        <Box  className='flex-col' gap={2} sx={{height:'100%'}}>
            <Box className='flex-row-between' alignItems={'center'}>
                <Typography sx={{...theme.typography.subtitle2, fontWeight:600}}>
                    Activity
                </Typography>
                <FilterMenu 
                    loadMoreHistory={loadMoreHistory}
                    setFilter={setFilter}
                    disabled={!hasHistory}/>
            </Box>
            <Box className='flex-col' sx={{...theme.standardBox, pt:2, gap:1}}>
                <Timeline
                    sx={{
                        p:0,
                        m:0,
                        [`& .${timelineItemClasses.root}:before`]: { flex: 0, padding: 0, },
                    }}>
                    {hasHistory?
                        historyState?.data?.map((entry, index) =>{
                            return(
                                <HistoryEntry key={index} entry={entry}/>
                            )
                        })
                    :!hasHistory && (isLoading)?
                        <Box className={'flex-col'} gap={2}>
                            {Array.from({ length: 6 }).map((_, index) => (
                                    <HistoryEntryLoader key={index}/>
                                ))}                        
                        </Box>
                    :!hasHistory?
                        <Box mt={3} mb={3}>
                            <DefaultNoRowsOverlay message="No history"/> 
                        </Box>
                        : null
                        
                        }
                    {loading? 
                        <CircularProgress sx={{alignSelf:'center'}}/> 

                    :hasHistory && !reachedMaxPages? 
                        <Box height={40} width={40} ></Box> : null}

                </Timeline>

               
            </Box>
        </Box>
    )


}


export default memo(PatientHistory)
